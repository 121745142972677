import React from "react"
import { useHistory, Link, FaIcon } from "lib"
import { useMainMenu } from "../header/MainMenu"

const getBreadcrumbs = (menu, toks, index) => {
    if (index > toks.length - 1) return []
    const path = toks.slice(0, index).join("/")
    //console.log(path)
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].p === path) {
            if (index === toks.length || !menu[i].items) return [menu[i]]
            return [menu[i], ...getBreadcrumbs(menu[i].items, toks, index + 1)]
        }
    }
    return []
}

const useBreadcrumbs = () => {
    const { location } = useHistory()
    const menu = useMainMenu()
    const { pathname } = location

    if (!menu) return []
    const toks = pathname.split("/")
    //console.log(toks, menu)
    return getBreadcrumbs(menu, toks, 2)
}

const Breadcrumb = () => {
    const breadcrumbs = useBreadcrumbs()
    if (breadcrumbs.length === 0) return null

    return (
        <div className="breadcrumbs">
            {breadcrumbs.map((b, i) => (
                <React.Fragment key={i}>
                    <div className="breadcrumb">
                        <Link to={b.p}>{b.title}</Link>
                    </div>
                    {i < breadcrumbs.length - 1 && <FaIcon icon="faAngleDoubleRight" size={14} />}
                </React.Fragment>
            ))}
        </div>
    )
}
export default Breadcrumb
