import React from "react"
import { FaIcon } from "lib"

const Slideshow = ({ domRef, info, value, children, ...props }) => {
    const slides = React.useRef([])
    const interval = React.useRef()
    const { state, setState } = info
    React.useEffect(() => {
        if (!value) return
        if (!state) {
            slides.current = value._o ? value._o.split(",") : Object.keys(value._e)
            if (slides.current.length === 0) return
            setState({
                active: slides.current[0],
            })
        }
    }, [value, setState, state])
    const next = React.useCallback(() => {
        if (!state || slides.current.length === 0) return
        const current = slides.current.indexOf(state.active)
        let next = current + 1
        if (next >= slides.current.length) next = 0
        setState({ prev: slides.current[current], active: slides.current[next] })
    }, [state, setState])
    React.useEffect(() => {
        interval.current = setInterval(next, 7500)
        return () => {
            if (!interval.current) return
            clearInterval(interval.current)
            interval.current = null
        }
    }, [next])
    if (!value) return null

    return (
        <div ref={domRef} {...props}>
            <div className="track">{children}</div>
            <nav className="pager-controls">
                <div className="pager-control prev" onClick={next}>
                    <FaIcon icon="navigation/chevron_left" size={48} />
                </div>
                <div className="pager-control next" onClick={next}>
                    <FaIcon icon="navigation/chevron_right" size={48} />
                </div>
            </nav>
        </div>
    )
}
export default React.memo(Slideshow)
/*
  {children.map((child, i) => (
  <div
    className={`slideshow-slide ${i === current ? " active" : ""} ${
    i === prev ? " prev" : ""
    }`}
    key={i}
    >
    {child}
  </div>
  ))}
*/
