import axios from "axios"
import React, { useCallback, useMemo, useState, useEffect, useRef } from "react"
import C from "../conf"
import { useScreen, useUnmounted } from "../hooks"
import { useIntersection } from "../intersection"
import { realPath, requestIdleCallback } from "../util"

const O = {
    landscape: "l",
    portrait: "p",
}
const cache = {}
const renderSVG = svgData => {
    if (!svgData || typeof window==='undefined') return null

    //console.log(svgData)
    const d = new window.DOMParser().parseFromString(svgData, "image/svg+xml")
    const props = {
        viewBox: d.documentElement.getAttribute("viewBox"),
        version: d.documentElement.getAttribute("version"),
        xmlns: d.documentElement.getAttribute("xmlns"),
        xmlnsXlink: d.documentElement.getAttribute("xmlns:xlink"),
    }
    const styles = d.getElementsByTagName("style")
    while (styles.length > 0) {
        d.documentElement.removeChild(styles[0])
    }
    //    console.log(d.documentElement.attributes)
    //  console.log(d.documentElement.innerHTML)
    //console.log(d.all)
    props.dangerouslySetInnerHTML = { __html: d.documentElement.innerHTML }
    return props
}

const useSVG = (isSVG, src) => {
    const [state, setState] = useState({ loaded: false, error: false })
    const unmounted = useUnmounted()
    if (!isSVG) return
    if (!state.loaded && !state.error)
        axios
            .get(realPath(src.url))
            .then(res => {
                if (unmounted.current) return
                if (res.status === 200) {
                    setState({ loaded: true, data: renderSVG(res.data), error: false })
                } else {
                    setState({ loaded: true, error: true })
                }
            })
            .catch(err => {
                setState({ loaded: true, error: true })
                console.log(err)
            })
    return state.loaded && !state.error ? state.data : null
}
const useImage = (src, preload, imageStyle, noth, svg, imgRef, domRef) => {
    const unmounted = useUnmounted()
    const prevUrl = useRef()
    const screen = useScreen(null, src && src.srcset)
    const loading = useRef(false)
    const [, tUpdate] = useState(0)

    const triggerUpdate = useCallback(() => {
        const setRender = frameStart => {
            if (unmounted.current) return
            const delta = performance.now() - frameStart
            if (delta > 10) {
                requestAnimationFrame(setRender)
                return
            }
            tUpdate(state => (state + 1) % 100)
        }
        requestAnimationFrame(setRender)
    }, [unmounted])

    const [url, imgSrc] = useMemo(() => {
        if (!src) return [null, null]
        if (svg) return [src.url, null]
        if (!src.srcset) return [realPath(src.url, imageStyle), src]
        //console.log(src, screen)
        const srcset = src.srcset.filter(
            item =>
                (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
                (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
                (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
        )
        //console.log(srcset)
        if (srcset.length === 0) return [realPath(src.url, imageStyle), src]
        return [realPath(srcset[0].src, imageStyle), srcset[0]]
    }, [src, screen, imageStyle, svg])
    const loadImage = useCallback(() => {
        const img = new Image()
        img.onload = () => {
            if (unmounted.current) return
            cache[url] = true
            requestIdleCallback(triggerUpdate, {
                timeout: Math.floor(Math.random() * Math.floor(1000)),
            })
        }
        img.onerror = () => {
            if (unmounted.current) return
            console.log(`Error loading image ${url}`)
        }
        img.src = url
        if (img.complete) {
            cache[url] = true
            requestIdleCallback(triggerUpdate, {
                timeout: Math.floor(Math.random() * Math.floor(1000)),
            })
            //triggerUpdate()
        }
    }, [url, unmounted, triggerUpdate])

    if (prevUrl.current !== url) {
        if (prevUrl.current) {
            //loading.current = false
            //console.log("set loading false")
            loadImage()
        }
        prevUrl.current = url
    }

    useEffect(() => {
        if (!url || svg || cache[url] || loading.current) return
        //if (!url || cache[url] || loading.current) return
        if (svg || preload) {
            loading.current = true
            loadImage()
        }
    }, [loadImage, svg, url, preload])
    //}, [url, svg, preload])

    const onIntersect = useCallback(
        entry => {
            if (unmounted.current || !url || svg || cache[url] || loading.current) return
            if (!entry.isIntersecting) return
            loading.current = true
            loadImage()
        },
        [url, loadImage, svg, unmounted]
    )
    useIntersection(svg ? null : domRef || imgRef, onIntersect)

    const aspect =
        imageStyle && C.styles[imageStyle] && !C.styles[imageStyle][2]
            ? (C.styles[imageStyle][1] / C.styles[imageStyle][0]) * 100
            : imgSrc && imgSrc.h && imgSrc.w
            ? (imgSrc.h / imgSrc.w) * 100
            : 1

    if (!url) return [null, null]
    if (svg) return [url, null]
    if (!cache[url] && imgSrc.th && !noth) {
        const toks = url.split(".")
        const ext = toks[toks.length - 1]
        if (ext === "png") return [`data:image/png;charset=utf-8;base64, ${imgSrc.th}`, aspect]
        return [`data:image/jpeg;charset=utf-8;base64, ${imgSrc.th}`, aspect]
    }
    return [url, aspect]
}

const LazyImage = (props, ref) => {
    const {
        domRef,
        src,
        imageStyle,
        svg,
        className,
        style,
        bg,
        alt,
        proportional,
        noth,
        data,
        preload,
        ...rest
    } = props
    //if (proportional) console.log(props)
    //if (src && src.mime !== "image/png") console.log(props)
    const imgRef = useRef()
    const isSVG = src && src.mime === "image/svg+xml"
    const [url, aspect] = useImage(
        src,
        preload,
        imageStyle || C.defaultImageStyle,
        noth,
        svg,
        imgRef,
        domRef
    )
    //console.log(src, url, aspect)
    const svgProps = useSVG(isSVG, src)

    let classes = className || ""
    let cstyle = style || {}
    let containerStyle, propContainerStyle

    if (bg) {
        if (proportional) {
            classes = `${classes} prop-container`
            propContainerStyle = { height: 0, paddingBottom: `${aspect}%` }
            containerStyle = { backgroundImage: `url('${url}')`, ...cstyle }
            return (
                <div className={classes} style={propContainerStyle}>
                    <div
                        ref={ref || domRef || imgRef}
                        className="lazy"
                        style={containerStyle}
                        {...rest}
                    >
                        {props.children}
                    </div>
                </div>
            )
        }
        classes = `${classes} lazy`
        containerStyle = { backgroundImage: `url('${url}')`, ...cstyle }
        return (
            <div ref={ref || domRef || imgRef} className={classes} style={containerStyle} {...rest}>
                {props.children}
            </div>
        )
    }
    if (proportional) {
        classes = `${classes} prop-container`
        propContainerStyle = {
            height: 0,
            paddingBottom: `${aspect}%`,
            ...cstyle,
        }
        /*return (
            <img
                ref={domRef || imgRef}
                src={url}
                className={`lazy ${classes}`}
                style={propContainerStyle}
                alt={alt || ""}
                {...rest}
            />
            )*/
        return (
            <div className={classes} style={propContainerStyle} {...rest}>
                {isSVG ? (
                    svgProps ? (
                        <svg {...svgProps} />
                    ) : null
                ) : (
                    <img ref={ref || domRef || imgRef} src={url} className="lazy" alt={alt || ""} />
                )}
            </div>
        )
    }
    classes = `${classes} lazy`
    if (svg)
        return (
            <image
                ref={ref || domRef || imgRef}
                xlinkHref={url}
                className={classes}
                alt={alt || ""}
                style={style}
                {...rest}
            />
        )

    return isSVG ? (
        svgProps ? (
            <svg {...svgProps} className={classes} style={style} {...rest} />
        ) : null
    ) : (
        <img
            ref={ref || domRef || imgRef}
            src={url}
            className={classes}
            alt={alt || ""}
            style={style}
            {...rest}
        />
    )
}
export default React.forwardRef(LazyImage)
