import { useMemo } from "react"
import customNodes from "conf/node"

export const isCustomNode = pathname => {
    if (pathname === "/shell")
        return { _id: {}, _custom: true, type: "page", title: "", path: pathname }
    if (customNodes[pathname])
        return { ...customNodes[pathname], path: pathname }
    return Object.keys(customNodes).reduce((acc, key) => {
        if (!!acc || !customNodes[key].pattern) return acc
        if (customNodes[key].pattern.test(pathname))
            return { ...customNodes[key], path: pathname }
        return acc
    }, false)
}
const useCustomNode = pathname => useMemo(() => isCustomNode(pathname), [pathname])
export default useCustomNode
