import axios from "axios"
//import C from "../conf"
//import { queryRefreshAll } from "lib/hooks/useQuery"
import { clearCache, refresh, fetch, execute, select } from "./useQuery"
import { serialize, ObjectId } from "bson"

const updateMany = (update, cb = null) => {
    let collection = update.collection || "node"
    return axios
        .put(`datamany/${collection}`, update)
        .then(response => {
            clearCache()
            if (cb) cb(response)
        })
        .catch(error => console.log(error))
}

const update = (update, cb = null) => {
    //console.log(update)
    //console.log()
    let id
    if (update.id) id = update.id
    else {
        if (update._id && update._id instanceof ObjectId) id = update._id.toString()
        else return
    }
    let collection = update.collection || "node"
    return axios
        .put(`data/${collection}/${id}`, { update: serialize(update) })
        .then(response => {
            clearCache()
            //console.log(response)
            if (cb) cb(response)
            return response
        })
        .catch(error => console.log(error))
}
const insert = (update, cb = null) => {
    let collection = update.collection || "node"
    return axios
        .post(`data/${collection}`, { update: serialize(update) })
        .then(response => {
            clearCache()
            //console.log(response)
            if (cb) cb(response)
            return response
        })
        .catch(error => console.log(error))
}

const findFilesRecursive = (entity, files) => {
    if (!entity) return
    if (typeof entity !== "object") return

    if (entity.url) {
        files.push(entity.url)
        return
    }
    if (Array.isArray(entity)) {
        entity.forEach(item => findFilesRecursive(item, files))
        return
    }
    Object.keys(entity).forEach(key => findFilesRecursive(entity[key], files))
}
const findFiles = entity => {
    let files = []
    findFilesRecursive(entity, files)
    return files
}
const remove = (id, collection = "node", cb = null) =>
    axios
        .delete(`data/${collection}/${id}`)
        .then(response => {
            clearCache()
            if (cb) cb(response)
        })
        .catch(error => console.log(error))

export default {
    select,
    update,
    insert,
    updateMany,
    findFiles,
    refresh,
    fetch,
    execute,
    remove,
}
