import React from "react"
import { realPath } from "../../util"

const videoSettings = ["controls", "playsInline", "muted", "autoPlay", "loop"]

const Vid = ({ domRef, info, value, ...props }) => {
    const [videoProps, rest] = React.useMemo(() => {
        const { videoProps, ...rest } = props
        let vprops = {}
        info.fieldInfo.settings.forEach(s => {
            if (!s.default || !videoSettings.includes(s.name)) return
            vprops[s.name] = s.default
        })
        vprops = { ...vprops, ...videoProps }
        videoSettings.forEach(s => {
            if (info.fieldInfo[s] !== undefined) vprops[s] = info.fieldInfo[s]
        })
        Object.keys(vprops).forEach(p => {
            if (vprops[p] === true || vprops[p] === 1) vprops[p] = true
            if (vprops[p] === false || vprops[p] === 0) delete vprops[p]
        })
        return [vprops, rest]
    }, [props, info])
    //console.log(videoProps, info, value)
    if (!value) return null
    return (
        <div ref={domRef} {...rest}>
            <video {...videoProps}>
                {value.map((v, i) => (
                    <source key={i} src={realPath(v.url)} type="video/mp4" />
                ))}
            </video>
        </div>
    )
}

export default Vid
