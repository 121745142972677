import React from "react"
import { documentStateTypes } from "conf/config"
const ACHIZITII_DIRECTE = "5e0cf2a1789470b55658674f" // 16

const State = ({ domRef, info, value, children, ...props }) => {
    const s = documentStateTypes.filter(s => s.val === value)
    const label = s.length > 0 ? s[0].label : ""
    if (!info.entity.cat || info.entity.cat.ref !== ACHIZITII_DIRECTE) return null

    return (
        <div ref={domRef} {...props} field-state={value}>
            {label}
        </div>
    )
}

export default State
