import React from "react"
import { useQuery, LazyImage } from "lib"
import TransitionGroup from "react-transition-group/TransitionGroup"
import CSSTransition from "react-transition-group/CSSTransition"

const qSplash = {
    collection: "splash",
    query: { active: 1 },
    limit: 1,
}
const useSplash = () => {
    const [data] = useQuery(qSplash)
    //console.log(data)
    return data?.[0]?.img?.[0]
}

const Splash = () => {
    const splash = useSplash()
    const [open, setOpen] = React.useState(true)
    const onClose = React.useCallback(() => {
        setOpen(false)
    }, [])
    if (!splash) return null

    return (
        <TransitionGroup id="splash-container" appear={true}>
            {open && (
                <CSSTransition timeout={1000} classNames="splash">
                    <div className="splash" onClick={onClose}>
                        <LazyImage src={splash} imageStyle="orig" alt="" />
                    </div>
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}
export default React.memo(Splash)
