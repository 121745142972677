import React from "react"
import { Link, FaIcon, useHistory } from "lib"
import { useMainMenu } from "../header/MainMenu"

const MenuItem = ({ item, path }) => {
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState(false)
    const hasSubmenu = item.items && item.items.length > 0
    const handleActivate = React.useCallback(
        e => {
            if (!hasSubmenu) return true
            if (selected && path === item.p) {
                setOpen(open => !open)
                return false
            }
            if (!open) {
                setOpen(true)
                return false
            }
            return true
        },
        [hasSubmenu, open, selected, path, item.p]
    )
    const toggleOpen = React.useCallback(e => {
        setOpen(open => !open)
    }, [])
    React.useEffect(() => {
        if (path && path.indexOf(item.p) === 0) {
            setOpen(true)
            setSelected(true)
        } else {
            setOpen(false)
            setSelected(false)
        }
    }, [path, item.p])
    let classes = hasSubmenu ? (open ? "has-submenu open" : "has-submenu closed") : ""
    if (selected) classes += " selected"
    return (
        <li className={classes}>
            {hasSubmenu && <FaIcon icon="faAngleRight" onClick={toggleOpen} />}
            <Link to={item.p} onActivate={handleActivate}>
                {item.title}
            </Link>
            {item.items && (
                <ul className="menu">
                    {item.items.map((item, i) => (
                        <MenuItem item={item} path={path} key={i} />
                    ))}
                </ul>
            )}
        </li>
    )
}

const MenuLeft = () => {
    const menu = useMainMenu()
    const { location } = useHistory()
    if (!menu) return null
    const path = location ? location.pathname : null
    return (
        <ul id="menu-left">
            {menu.map((item, i) => (
                <MenuItem path={path} item={item} key={i} />
            ))}
        </ul>
    )
}

export default MenuLeft
