import site from "conf/site"
import C from "../conf"

let ws

const updatePageStyle = style => {
    const links = document.getElementsByTagName("link")
    for (let i in links) {
        const link = links[i]
        if (
            !link ||
            !link.getAttribute ||
            link.getAttribute("rel") !== "stylesheet" ||
            link.href.indexOf(C.BASE) < 0
        )
            continue

        if (style) link.href = `${C.BASE}${style}`
    }
}
const process = data => {
    try {
        const cmd = JSON.parse(data.data)
        switch (cmd.cmd) {
            case "style":
                updatePageStyle(cmd.style)
                break
            default:
                console.log("Unknown command", cmd)
        }
    } catch (e) {
        console.log(e)
    }
}
const connect = token => {
    if (ws) return

    let args
    if (token) {
        args = ["token", token]
    }
    ws = new WebSocket(`wss://${site.host}/wss`, args)
    //const ws = new WebSocket("wss://127.0.0.1:3006")
    ws.onopen = () => {
        ws.send(JSON.stringify({ cmd: "noop" }))
    }

    ws.onmessage = data => {
        //console.log(data)
        process(data)
    }
}
const disconnect = () => {
    if (ws) {
        ws.close()
        ws = null
    }
}
export const authWS = token => {
    disconnect()
    connect(token)
}
export const logoutWS = () => {
    disconnect()
    connect()
}

export const useWS = () => {
    if (typeof window === "undefined") return
    connect()
}
