import React from "react"
import { Field } from "lib"

const Quote = ({ info, value }) => {
    if (!value) return null

    return (
        <>
            <Field info={info} field="content" noadmin />
            <Field info={info} field="source" noadmin />
        </>
    )
}
export default React.memo(Quote)
