// @flow
import NProgress from "nprogress"
import C from "../conf"

type Param = [string, string]
type CallBack = (path: string, search: Array<Param>) => void

let onChangeListeners = []

let location = {}
let prevLocation = {}

const prepareSearch = (search: ?string): Array<any> => {
    if (!search) return []
    return search
        .slice(1)
        .split("&")
        .map(item => item.split("="))
        .reduce(
            (params, param) => ({
                ...params,
                [param[0]]: param[1],
            }),
            {}
        )
}
const getLanguage = pathname => {
    if (!C.LANGUAGES) return null

    const toks = pathname.split("/")
    if (toks.length < 2) return C.LANGUAGES[0]
    const lang = toks[1]
    if (C.LANGUAGES.indexOf(lang) < 0) {
        return C.LANGUAGES[0]
    }
    return lang
}
const push = (pathname: string) => {
    if (window !== window.top) {
        window.top.pushLocation(`${pathname}?mode=design`)
        return
    }
    realPushLocation(pathname)
}
const pushChildLocation = (pathname: string) => {
    realPushLocation(pathname)
}
const realPushLocation = (pathname: string) => {
    //console.log(pathname)
    prevLocation = { ...location }
    const prevPathname = location.pathname
    location = {}
    const language = getLanguage(pathname)
    requestAnimationFrame(() => {
        window.history.pushState({ pathname }, "", pathname)
        location.pathname = document.location.pathname
        location.hash = document.location.hash
        location.language = language
        location.search = prepareSearch(document.location.search)
        if (
            prevPathname !== document.location.pathname &&
            !(location.search["mode"] && location.search["mode"] === "design")
        ) {
            //console.log(location, document.location.pathname)
            requestAnimationFrame(() => {
                NProgress.configure({ parent: "body" })
                NProgress.start()
            })
        }
        onChangeListeners.forEach(callback =>
            callback(location.pathname, location.search, location.language)
        )
    })
}

const addLocationListener = (cb: CallBack) => onChangeListeners.push(cb)
const removeLocationListener = (cb: CallBack) => {
    onChangeListeners = onChangeListeners.filter(fn => fn !== cb)
}

const isSSR = typeof window === "undefined"
if (!isSSR) {
    location.pathname = document.location.pathname
    location.hash = document.location.hash
    location.search = prepareSearch(document.location.search)
    location.language = getLanguage(location.pathname)
    window.pushLocation = realPushLocation
    window.pushChildLocation = pushChildLocation
    if (!location.search["mode"] || location.search["mode"] !== "design") NProgress.start()
    window.onpopstate = e => {
        if (!e.state) return
        const loc = { ...location }
        //console.log(loc, e)
        if (prevLocation && e.state && e.state.pathname === prevLocation.pathname)
            location = { ...prevLocation }
        else location = {}
        prevLocation = loc
        //console.log(location.pathname, document.location)
        if (location.pathname !== document.location.pathname) {
            NProgress.configure({ parent: "body" })
            NProgress.start()
        }

        location.pathname = document.location.pathname
        location.hash = document.location.hash
        location.search = prepareSearch(document.location.search)
        location.language = getLanguage(location.pathname)
        onChangeListeners.forEach(callback =>
            callback(location.pathname, location.search, location.language)
        )
    }
}
const ssrSetLocation = (pathname, search) => {
    location.pathname = pathname
    location.hash = null
    location.search = search
    location.language = getLanguage(location.pathname)
}

const getLocation = () => ({ ...location })
const getPrevPage = () => prevLocation

export default {
    ssrSetLocation,
    getLocation,
    push,
    addLocationListener,
    removeLocationListener,
    getPrevPage,
    prepareSearch,
}
