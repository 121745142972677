import React, { Suspense, useContext, useMemo } from "react"
import { AuthContext } from "../auth"
import Field from "./Field"
import Entity from "../entity"

const FieldManager = React.lazy(() =>
    import("admin").then(module => ({ default: module.FieldManager }))
)

const getFields = layout => {
    let fields = []
    for (let i = 0; i < layout.length; i++) {
        if (typeof layout[i] === "string") fields.push(layout[i])
        else {
            if (Array.isArray(layout[i])) {
                if (layout[i].length === 1)
                    fields = [...fields, ...getFields(layout[i][0].slice(1))]
                else fields = [...fields, ...getFields(layout[i].slice(1))]
            }
        }
    }
    return fields
}
const renderExtra = (info, layout, o) => {
    const layoutFields = getFields(layout)
    const extra = o.filter(f => !layoutFields.includes(f))
    return extra.map((f, i) => <Field key={i} field={f} info={info} />)
}
export const renderLayout = (info, layout, o, level = 0) => (
    <>
        {layout.map((item, i) => (
            <React.Fragment key={i}>
                {typeof item === "string" ? (
                    <Field field={item} info={info} />
                ) : Array.isArray(item) ? (
                    item.length === 1 ? (
                        <div className={`group group-${item[0][0]}`}>
                            <div className="group-inner">
                                {renderLayout(info, item[0].slice(1), o, level + 1)}
                            </div>
                        </div>
                    ) : (
                        <div className={`group group-${item[0]}`}>
                            {renderLayout(info, item.slice(1), o, level + 1)}
                        </div>
                    )
                ) : null}
            </React.Fragment>
        ))}
        {level === 0 && <React.Fragment key="extra">{renderExtra(info, layout, o)}</React.Fragment>}
    </>
)

const Region = props => {
    const { entity, language, region, style, nowrap, dynamic, children } = props
    const { user } = useContext(AuthContext)

    const currentRegion = region || "content"
    const info = useMemo(
        () => ({
            entity,
            entityInfo: Entity.getInfo(entity),
            displayInfo: Entity.getDisplayInfo(entity),
            user,
            region,
            language,
        }),
        [entity, user, region, language]
    )

    let o
    if (
        entity._o &&
        ((currentRegion === "content" && typeof entity._o === "string") ||
            (typeof entity._o !== "string" && entity._o[currentRegion]))
    ) {
        o = (typeof entity._o === "string" ? entity._o : entity._o[currentRegion])
            .split(",")
            .filter(f => {
                const fs = Entity.getFields(entity).filter(f1 => f1.name === f)
                if (fs.length === 0) return false
                //const r = fs[0].region
                const displayInfo = info.displayInfo[f]

                const r = displayInfo ? displayInfo.region : undefined
                return (
                    (currentRegion === "content" &&
                        displayInfo &&
                        (r === undefined || r.trim() === "")) ||
                    currentRegion === r
                )
            })
    } else {
        o = Entity.getFields(entity)
        if (currentRegion === "content")
            o = o.filter(
                f =>
                    info.displayInfo[f.name] &&
                    (!info.displayInfo[f.name].region ||
                        info.displayInfo[f.name].region === "content")
            )
        else
            o = o.filter(
                f => info.displayInfo[f.name] && info.displayInfo[f.name].region === currentRegion
            )
        o = o.map(f => f.name)
    }
    if (nowrap)
        return (
            <>
                {info.displayInfo._layout
                    ? renderLayout(info, info.displayInfo._layout, o)
                    : o.map((f, i) => <Field key={i} field={f} info={info} />)}
                {user && dynamic && (
                    <Suspense fallback={<div>...</div>}>
                        <FieldManager user={user} entity={entity} region={currentRegion} />
                    </Suspense>
                )}
            </>
        )
    const otherAttributes = { [`region-${currentRegion}`]: "" }

    return (
        //<Delayed maxDelay={200}>
        <div style={style} {...otherAttributes}>
            <div region-inner="">
                {info.displayInfo._layout
                    ? renderLayout(info, info.displayInfo._layout, o)
                    : o.map((f, i) => <Field key={i} field={f} info={info} />)}
                <React.Fragment>{children}</React.Fragment>
            </div>
            {user && dynamic && (
                <Suspense fallback={null}>
                    <FieldManager user={user} entity={entity} region={currentRegion} />
                </Suspense>
            )}
        </div>
        //</Delayed>
    )
}
export default React.memo(Region)
