import React, { useState, useCallback } from "react"
import { FaIcon, Link, useSubscription } from "lib"

const renderLink = (item, i, handleActivate, IconComp) => (
    <li key={i} className={item.items && item.items.length > 0 ? "has-submenu" : ""}>
        <Link to={item.p} onActivate={handleActivate}>
            {IconComp} {item.title}
        </Link>
        {item.items && (
            <ul className="menu">
                {item.items.map((item, i) => renderLink(item, i, handleActivate))}
            </ul>
        )}
    </li>
)

const MobileMenu = () => {
    const [mainMenu] = useSubscription("mainMenu")
    //console.log(menus)
    //const { mainMenu, secMenu }
    const [open, setOpen] = useState(false)
    const handleToggle = useCallback(() => {
        setOpen(open => !open)
    }, [])
    const handleActivate = useCallback(() => {
        setOpen(false)
        return true
    }, [])
    if (!mainMenu) return null

    return (
        <>
            <div id="mobile-menu-button" onClick={handleToggle}>
                <FaIcon icon="faBars" />
            </div>
            <div id="mobile-menu" className={open ? "open" : ""}>
                <div>
                    {mainMenu && (
                        <ul key={1} className="mobile-main-menu">
                            {mainMenu.map((item, i) => renderLink(item, i, handleActivate))}
                        </ul>
                    )}
                </div>
            </div>
        </>
    )
}

export default React.memo(MobileMenu)
