import React from "react"
//import { LazyImage } from ".."
//import { renderVideo } from "../../lib/util"
import RawDraft from "./RawDraft"
import RawSlate from "./RawSlate"
//import htmlRules from "admin/editor/html/html-serializer"
//import Html from "slate-html-serializer"
/*
const parseHTML = s => {
    const parsed = new DOMParser().parseFromString(s, "text/html")
    console.log(parsed)
}*/
/*
const stripTags = text => {
    
    let ret = text
    //let changed = false
    //let res = [...text.matchAll(/<([^\s]+)[^>]*>(.*)<\/\1>/g)]
    let res
    res = [...ret.matchAll(/<[^>]+>/g)]
    if (res.length > 0) {
        //changed = true
        res.forEach(m => {
            ret = ret.replace(m[0], "")
        })
    }
    res = [...ret.matchAll(/<\/[^>]+>/g)]
    if (res.length > 0) {
        //changed = true
        res.forEach(m => {
            ret = ret.replace(m[0], "")
        })
    }
    res = [...ret.matchAll(/<[^>]+\/>/g)]
    if (res.length > 0) {
        //changed = true
        res.forEach(m => {
            ret = ret.replace(m[0], "")
        })
    }
    return ret
    //if (!changed) return text
    //return stripTags(ret)
}*/
const getTrim = (text, trim) => {
    //const res = stripTags(text)
    const res = text.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, " ")
    return res.length > trim ? res.substring(0, trim) + "..." : res
}

const HTML = props => {
    const { info, value } = props
    //console.log("render HTML", props)
    if (!value) return null
    if (typeof value === "string") {
        if (info.fieldInfo.trim || info.displayInfo.trim) {
            //const html = new Html({ rules: htmlRules }).deserialize(value)
            //console.log(value, html)
            //parseHTML(value)
            //return trimHTML(value, info.fieldInfo.trim)
            //return <RawSlate {...props} value={html.toJSON()} />
            return getTrim(value, info.fieldInfo.trim || info.displayInfo.trim)
        }
        return <div className="field-inner" dangerouslySetInnerHTML={{ __html: value }} />
    }
    if (value.raw) return <RawDraft {...props} />
    if (value.document) return <RawSlate {...props} />
    return "slate"
}
export default React.memo(HTML)
