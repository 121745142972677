//import { entityFields } from "./entityFields"
import { fieldTypes } from "./fieldTypes"
import { pathFromField } from "./util"
import { getType, getTypeInfo } from "./types"
import { display } from "conf/display"

const cache = new WeakMap()

const _buildFieldInfoRec = fieldInfo => {
    if (!fieldInfo || !fieldTypes[fieldInfo.type] || fieldInfo.primitive) return fieldInfo

    const { name, type, primitive, typeName, ...primitiveFieldInfo } = fieldTypes[fieldInfo.type]
        .primitive
        ? fieldTypes[fieldInfo.type]
        : _buildFieldInfoRec(fieldTypes[fieldInfo.type])
    const info = {
        ...primitiveFieldInfo,
        ...fieldInfo,
        type,
        typeName: fieldInfo.typeName || fieldInfo.type,
        settings: {},
    }
    if (primitiveFieldInfo.settings)
        primitiveFieldInfo.settings.forEach(item => {
            info.settings[item.name] = item
        })
    if (fieldTypes[fieldInfo.typeName] && fieldTypes[fieldInfo.typeName].settings)
        fieldTypes[fieldInfo.typeName].settings.forEach(item => {
            info.settings[item.name] = item
        })
    info.settings = Object.values(info.settings)
    return info
}

export const buildFieldInfo = (fieldInfo, value) => {
    //console.log("buildFieldInfo", fieldInfo, value)
    const info = _buildFieldInfoRec(fieldInfo)
    //console.log(info)
    if (!info) return fieldInfo

    if (value && value._e) {
        if (!info.fields) info.fields = []
        let extraFields = []
        Object.keys(value._e).forEach(fieldName => {
            let exist = false
            info.fields = info.fields.map(field => {
                if (field.name === fieldName) {
                    exist = true
                    return { ...field, ...value._e[fieldName] }
                }
                return field
            })
            if (!exist)
                extraFields.push(
                    buildFieldInfo({
                        ...value._e[fieldName],
                        name: fieldName,
                        typeName: value._e[fieldName].type,
                    })
                )
        })
        info.fields = [...info.fields, ...extraFields]
    }
    //console.log(fieldInfo, value, info)
    return info
}
const _fieldInfoRecursive = (parent, parentFieldInfo, path, pathIndex) => {
    //console.log(parent, parentFieldInfo, path, pathIndex)
    switch (parentFieldInfo.type) {
        case "ref":
            const refInfo = getTypeInfo(parentFieldInfo.ref)
            return _fieldInfoRecursive(
                parent,
                { type: "obj", fields: refInfo.fields },
                path,
                pathIndex
            )
        case "list":
            if (Number.isInteger(path[pathIndex])) {
                const listIndex = path[pathIndex]
                const item = parent ? parent[listIndex] : null
                const itemFieldInfo = buildFieldInfo(
                    {
                        type: parentFieldInfo.items,
                        ...(parentFieldInfo._items && parentFieldInfo._items[listIndex]
                            ? parentFieldInfo._items[listIndex]
                            : []),
                    },
                    item
                )
                if (pathIndex === path.length - 1) return itemFieldInfo
                return _fieldInfoRecursive(item, itemFieldInfo, path, pathIndex + 1)
            } else {
                const itemsFieldInfo = buildFieldInfo({ type: parentFieldInfo.items })
                const item = parent ? parent[0] : null
                return _fieldInfoRecursive(item, itemsFieldInfo, path, pathIndex + 1)
            }
        case "obj": {
            const fields = parentFieldInfo.fields.filter(f => f.name === path[pathIndex])
            if (fields.length === 0) return null
            const child = parent ? parent[path[pathIndex]] : null
            const fieldInfo = buildFieldInfo(fields[0], child)
            if (pathIndex === path.length - 1) return fieldInfo
            return _fieldInfoRecursive(child, fieldInfo, path, pathIndex + 1)
        }
        default:
            return null
    }
}
export const resetInfo = entity => {
    if (cache.has(entity._id)) cache.delete(entity._id)
}
export const getInfo = entity => {
    if (cache.has(entity._id)) return cache.get(entity._id)

    const typeInfo = getTypeInfo(getType(entity))
    if (!typeInfo) return null
    //console.log(typeInfo.fields, entity._e)
    const defaultFields = typeInfo.fields.map(f => f.name)
    const fields = typeInfo.fields.reduce(
        (acc, f) => ({ ...acc, [f.name]: { ...f, ...(acc[f.name] || {}) } }),
        entity._e || {}
    )
    const fieldsOrder = [...new Set([...defaultFields, ...Object.keys(entity._e || {})]).values()]
    //console.log(fieldsOrder)
    const info = {
        ...typeInfo,
        fieldInfo: {},
        defaultFields,
        fields: fieldsOrder.map(name => buildFieldInfo(fields[name], entity[name])),
    }
    //console.log(info)
    //console.log(entity)
    cache.set(entity._id, info)
    return info
}

export const getFields = entity => {
    const info = getInfo(entity)
    return info ? info.fields : []
}
export const getFieldInfo = (parent, field, parentInfo) => {
    //console.log(parent, field, parentInfo)
    if (field == null || field === undefined) return null
    if (!parentInfo) {
        // parent is entity
        if (!parent) return null
        const info = getInfo(parent)
        if (!info) return null
        if (info.fieldInfo[field]) return info.fieldInfo[field]
        const fieldInfo = _fieldInfoRecursive(
            parent,
            { type: "obj", fields: info.fields },
            pathFromField(field),
            0
        )
        info.fieldInfo[field] = fieldInfo
        //console.log(fieldInfo)
        return fieldInfo
    }
    const fieldInfo = _fieldInfoRecursive(parent, parentInfo, pathFromField(field), 0)
    //console.log(fieldInfo)
    return fieldInfo
}
export const getCollection = entity => {
    const info = getInfo(entity)
    return info ? info.collection : null
}
export const is = (entity, typeClass) => {
    const info = getInfo(entity)
    return info && info.typeClasses ? info.typeClasses.includes(typeClass) : false
}
export const getDisplayInfo = (entity, displayName) => {
    const info = getInfo(entity)
    const displayInfo = display[displayName || "default"] || {}
    const entityDisplayInfo = displayInfo[entity.type]
    const ret = {}
    if (displayInfo && displayInfo._layout) ret._layout = displayInfo._layout
    if (entityDisplayInfo && entityDisplayInfo._layout) ret._layout = entityDisplayInfo._layout
    if (!info) return ret
    info.fields.forEach(field => {
        //console.log(field)
        if (!entityDisplayInfo) {
            if (!!displayName) {
                //console.log(field.name, displayInfo[field.name])
                if (!displayInfo[field.name]) return
                if (displayInfo[field.name] === true) {
                    ret[field.name] = {
                        ...(displayInfo[field.typeName] || {}),
                    }
                    return
                }
                ret[field.name] = {
                    ...(displayInfo[field.typeName] || {}),
                    ...(displayInfo[field.name] || {}),
                }
            } else {
                if (displayInfo[field.name] === false) return
                ret[field.name] = {
                    ...(displayInfo[field.typeName] || {}),
                    ...(displayInfo[field.name] || {}),
                }
            }
            return
        }
        //console.log(displayName, info, displayInfo, entityDisplayInfo, field)
        if (
            entityDisplayInfo[field.name] === false ||
            (displayInfo[field.name] === false && !entityDisplayInfo[field.name])
        )
            return
        //console.log("2", field.name, displayInfo[field.name], entityDisplayInfo[field.name])
        if (!!displayName) {
            if (!entityDisplayInfo[field.name]) return
            if (entityDisplayInfo[field.name] === true) {
                ret[field.name] = {
                    ...(displayInfo[field.typeName] || {}),
                    ...(displayInfo[field.name] || {}),
                }
                return
            }
        }
        ret[field.name] = {
            ...(displayInfo[field.typeName] || {}),
            ...(displayInfo[field.name] || {}),
            ...(entityDisplayInfo[field.typeName] || {}),
            ...(entityDisplayInfo[field.name] || {}),
        }
    })
    //console.log(displayName, info, displayInfo, entityDisplayInfo, ret)
    return ret
}
export const getFieldDisplayInfo = (entity, field, displayName) => {
    const displayInfo = display[displayName || "default"] || {}
    if (!field || !entity) return {}
    const entityDisplayInfo = displayInfo[entity.type]
    //console.log(entity, field, displayName, displayInfo, entityDisplayInfo)
    if (!entityDisplayInfo) {
        if (!!displayName) {
            //console.log(field.name, displayInfo[field.name])
            if (!displayInfo[field.name]) return false
            if (displayInfo[field.name] === true) {
                return {
                    ...(displayInfo[field.typeName] || {}),
                }
            }
            return {
                ...(displayInfo[field.typeName] || {}),
                ...(displayInfo[field.name] || {}),
            }
        } else {
            if (displayInfo[field.name] === false) return false
            return {
                ...(displayInfo[field.typeName] || {}),
                ...(displayInfo[field.name] || {}),
            }
        }
    }

    if (
        entityDisplayInfo[field.name] === false ||
        (displayInfo[field.name] === false && !entityDisplayInfo[field.name])
    )
        return false
    if (!!displayName) {
        if (!entityDisplayInfo[field.name]) return false
        if (entityDisplayInfo[field.name] === true) {
            return {
                ...(displayInfo[field.typeName] || {}),
                ...(displayInfo[field.name] || {}),
            }
        }
    }
    return {
        ...(displayInfo[field.typeName] || {}),
        ...(displayInfo[field.name] || {}),
        ...(entityDisplayInfo[field.typeName] || {}),
        ...(entityDisplayInfo[field.name] || {}),
    }
}
