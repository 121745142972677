export const display = {
    default: {
        _seo: false,
        header: { region: "hero" },
        title: { tag: "h1" },
        showTime: false,
        cat: false,
        person: false,
        year: false,
        summary: false,
        thumb: false,
        special: false,
        page: {
            title: { region: "hero" },
        },
        docList: {
            cat: true,
        },
        document: {
            _layout: [
                "title",
                "breadcrumb",
                "state",
                ["docs", ["date", "subtitle", "date"], "docs"],
                "body",
            ],
        },
    },
    teaser: {
        title: { link: true }, //tag: "h3",
        summary: true,
        thumb: true,
    },
    simple: {
        title: { link: true }, //tag: "div",
        summary: true,
        thumb: true,
        date: true,
        state: true,
    },
    special: {
        document: {
            title: { link: true }, //tag: "div",
            subtitle: true,
            summary: true,
            thumb: { link: true },
            date: true,
            state: true,
            body: true,
            docs: true,
            _layout: ["date", "thumb", ["content", "title", "subtitle", "body"], "docs"],
        },
    },
}
