import Text from "./Text"
import HTML from "./HTML"
import Img from "./Img"
import Date from "./Date"
import Form from "./forms/Form"
import FormField from "./forms/FormField"
import Video from "./Video"
import Vid from "./Vid"
import View from "./View"
import Any from "./Any"
import { fieldRenderer } from "conf/fieldRenderer"

const defaultRenderers = {
    text: Text,
    int: Text,
    html: HTML,
    date: Date,
    img: Img,
    Form,
    FormField,
    any: Any,
    obj: Any,
    video: Video,
    vid: Vid,
    View,
}
const renderers = Object.assign({}, defaultRenderers, fieldRenderer)
export default renderers
