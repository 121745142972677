import React, { Suspense } from "react"

const StyleDialogContent = React.lazy(() =>
    import("admin").then(module => ({ default: module.StyleDialogContent }))
)

const Shell = ({ user, logout, node, pathname, design }) => {
    if (!user) return null
    return (
        <>
            <Suspense fallback={null}>
                <StyleDialogContent withAppBar />
            </Suspense>
        </>
    )
}
export default Shell
/*import React from "react"
import { useAuthContext } from "lib"
import Admin from "./Admin"

const Shell = () => {
    const { user, logout } = useAuthContext()
    return (
        <>
            shell
            <div id="modal-container" />
            <Admin user={user} logout={logout} />
        </>
    )
}
export default Shell
*/
