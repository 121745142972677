import React from "react"
import { Field } from "lib"

const CardList = ({ domRef, info, value, ...props }) => {
    if (!value) return null
    //console.log(value)
    return (
        <ul ref={domRef} {...props}>
            {value.map((card, i) => (
                <Field key={i} info={info} field={i} tag="li" />
            ))}
        </ul>
    )
}
export default React.memo(CardList)
