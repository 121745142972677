import React from "react"
import { FaIcon, realPath } from "lib"
const known_types = ["pdf", "doc", "docx", "xls", "xlsx"]
const img_types = ["jpg", "png", "gif"]

const DocViewer = ({ url, classes }) => {
    const [state, setState] = React.useState({ loaded: false, i: 0 })
    const docLoaded = React.useCallback(() => setState(state => ({ ...state, loaded: true })), [])
    const reloadDoc = React.useCallback(() => setState(state => ({ ...state, i: state.i + 1 })), [])
    return (
        <div className={"doc-viewer " + (classes || "") + (state.loaded ? " loaded" : " loading")}>
            <div className="content">
                {!state.loaded && (
                    <div className="loading">
                        <FaIcon icon="faSpinner" size={24} />
                    </div>
                )}
                <iframe
                    title="doc"
                    key={state.i}
                    onLoad={docLoaded}
                    onError={reloadDoc}
                    width="100%"
                    src={
                        "https://docs.google.com/viewer?url=" +
                        encodeURIComponent(realPath(url)) +
                        "&embedded=true"
                    }
                ></iframe>
            </div>
        </div>
    )
}
const getName = doc => {
    if (doc.name) return doc.name
    if (doc.description) return doc.description
    if (!doc.url) return ""
    const toks = doc.url.split("/")
    return toks[toks.length - 1]
}

const DocDefault = ({ domRef, info, value, ...props }) => {
    const [docIndex, setDocIndex] = React.useState(0)
    const handleDocSelect = React.useCallback(
        e => setDocIndex(parseInt(e.currentTarget.dataset.doc, 10)),
        []
    )
    const docs = React.useMemo(
        () =>
            value
                ? value.map(doc => {
                      const fileType = doc.url
                          .split(".")
                          .pop()
                          .toLowerCase()
                      const isDoc = known_types.includes(fileType)
                      const isImg = img_types.includes(fileType)
                      const isViewable = isDoc || isImg
                      return {
                          name: getName(doc),
                          url: doc.url,
                          fileType,
                          isDoc,
                          isImg,
                          isViewable,
                      }
                  })
                : null,
        [value]
    )
    //const [docIndex, setDocIndex] = React.useState(0)
    //console.log(info, value, props)
    if (!docs || docs.length === 0) return null
    const doc = docs[docIndex]
    return (
        <div ref={domRef} {...props}>
            <div className="header">
                <div className="document-list">
                    {docs.map((f, j) => (
                        <div key={j} className="document">
                            <div
                                className={`doc-select${f.isViewable ? " viewable" : ""}${
                                    docIndex === j ? " selected" : ""
                                }`}
                                data-doc={j}
                                onClick={handleDocSelect}
                            >
                                <FaIcon
                                    icon={
                                        f.isViewable
                                            ? docIndex === j
                                                ? "solid/faEye"
                                                : "regular/faEye"
                                            : "regular/faEyeSlash"
                                    }
                                    size={24}
                                />
                            </div>
                            <div className="doc-name">{f.name}</div>
                            <a href={realPath(f.url)} className="download">
                                <FaIcon icon="faFileDownload" size={24} />
                            </a>
                        </div>
                    ))}
                </div>
                <div className="info">Pentru descărcarea documentelor apăsaţi pe iconiţă</div>
            </div>
            {doc.isDoc && <DocViewer url={doc.url} classes="embed-paper" />}
            {doc.isImg && (
                <div className="img-doc">
                    <div className="content">
                        <img
                            src={realPath(doc.url)}
                            alt="doc"
                            style={{ display: "block", margin: "20px auto" }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
const DocSpecial = ({ domRef, info, value, ...props }) => {
    const docs = React.useMemo(
        () =>
            value
                ? value.map(doc => {
                      const fileType = doc.url
                          .split(".")
                          .pop()
                          .toLowerCase()
                      const isDoc = known_types.includes(fileType)
                      const isImg = img_types.includes(fileType)
                      const isViewable = isDoc || isImg
                      return {
                          name: getName(doc),
                          url: doc.url,
                          fileType,
                          isDoc,
                          isImg,
                          isViewable,
                      }
                  })
                : null,
        [value]
    )
    //const [docIndex, setDocIndex] = React.useState(0)
    //console.log(info, value, props)
    if (!docs || docs.length === 0) return null

    return (
        <div ref={domRef} {...props}>
            <div className="header">
                <div className="document-list-special">
                    {docs.map((f, j) => (
                        <div key={j} className="document">
                            <a href={realPath(f.url)} className="download">
                                <FaIcon icon="faFileDownload" size={32} />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
const Doc = ({ domRef, info, value, ...props }) => {
    if (info.display === "special")
        return <DocSpecial domRef={domRef} info={info} value={value} {...props} />
    return <DocDefault domRef={domRef} info={info} value={value} {...props} />
}
export default React.memo(Doc)
