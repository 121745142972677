import { nodeTypes as customNodeTypes, entityTypes as customEntityTypes } from "conf/entityAdmin"
import { entityFields } from "./entityFields"

const entitySettings = [
    {
        name: "_nodel",
        type: "bool",
        label: "Nu se poate șterge",
        widget: "toggle",
        require: "admin",
    },
    { name: "_cssid", type: "text", label: "CSS ID" },
    { name: "_class", type: "text", label: "Clasa CSS" },
]
const implicitNodeFields = [{ name: "_seo", type: "seo", label: "SEO", region: "SEO" }]
const entityTypes = {} //Object.assign({}, customEntityTypes)
Object.keys(customNodeTypes).forEach(type => {
    entityTypes[type] = {
        ...customNodeTypes[type],
        type,
        typeClasses: ["node", "entity"],
        collection: "node",
        settings: [...entitySettings, ...(customNodeTypes[type].settings || [])],
        fields: [...implicitNodeFields, ...entityFields[type]].map(field =>
            customNodeTypes[type].fields && customNodeTypes[type].fields[field.name]
                ? { ...field, ...customNodeTypes[type].fields[field.name] }
                : field
        ),
    }
})
Object.keys(customEntityTypes).forEach(type => {
    entityTypes[type] = {
        ...customEntityTypes[type],
        type,
        typeClasses: ["entity"],
        collection: customEntityTypes[type].collection || type,
        settings: [...entitySettings, ...(customEntityTypes[type].settings || [])],
        fields: entityFields[type].map(field =>
            customEntityTypes[type].fields && customEntityTypes[type].fields[field.name]
                ? { ...field, ...customEntityTypes[type].fields[field.name] }
                : field
        ),
    }
})
export const getType = entity => entity.type
export const getTypeInfo = type => entityTypes[type]
export { entityTypes }
