import React from "react"
import { useMainMenu } from "../header/MainMenu"
import { Link } from "lib"

const findRecursive = (menu, searchLocation) => {
    const { p, items } = menu
    if (p === searchLocation) {
        return menu
    }
    if (!items) return null

    for (let i in items) {
        let parent = findRecursive(items[i], searchLocation)
        if (parent) return parent
    }
    return null
}
const findParent = (menu, searchLocation) => {
    if (!menu) return null

    let parent = null
    for (let i in menu) {
        parent = findRecursive(menu[i], searchLocation)
        if (parent) return parent
    }
    return null
}

const useSiblings = entity => {
    const menu = useMainMenu()
    return React.useMemo(() => {
        if (!entity || !entity.path) return {}

        let location = entity.path
        let toks = location.split("/")
        toks.pop()
        if (entity.type === "document") {
            location = toks.join("/")
            toks.pop()
        }
        const searchLocation = toks.join("/")
        let parent = findParent(menu, searchLocation)
        //console.log(parent)
        if (parent) {
            return { location, parent }
        }

        parent = findParent(menu, entity.path)
        //console.log(parent)
        return { location: entity.path, parent }
    }, [entity, menu])
}
const Siblings = ({ entity }) => {
    const { location, parent } = useSiblings(entity)
    //console.log(location, parent)
    return (
        <div className="siblings">
            <div className="siblings-inner">
                {parent && (
                    <h3>
                        <Link to={parent.p}>{parent.title}</Link>
                    </h3>
                )}
                {parent && (
                    <div className="items">
                        {parent.items &&
                            parent.items.map((sibling, i) => (
                                <div
                                    key={i}
                                    className={"item" + (sibling.p === location ? " selected" : "")}
                                >
                                    <Link to={sibling.p}>{sibling.title}</Link>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    )
}
export default Siblings
