import React from "react"
import { Transition, TransitionGroup } from "react-transition-group"
import NodeInner from "./NodeInner"

import SEO from "./SEO"
import {Entity} from "lib"

const Node = ({ location, search, language, node, first }) => {
    const classes = `node node-${first ? "first" : "notfirst"}`
    //console.log("RENDER NODE", node)
    if (!node) return null
    return (
        <>
            <SEO node={node} language={language} />
            <TransitionGroup className={classes} appear={true} enter={true}>
                <Transition
                    key={`${language}:${Entity.getId(node)}`}
                    unmountOnExit={true}
                    timeout={1000}
                >
                    {status => (
                        <NodeInner
                            status={status}
                            location={location}
                            search={search}
                            language={language}
                            node={node}
                            first={first}
                        />
                    )}
                </Transition>
            </TransitionGroup>
        </>
    )
}

export default React.memo(Node)
