import React from "react"
import { Field, useScreen } from "lib"
//const known_types = ["pdf", "doc", "docx", "xls", "xlsx"]
//const img_types = ["jpg", "png", "gif"]

const Slide = ({ domRef, info, value, className, ...props }) => {
    const { state, field } = info
    //console.log(value)
    const screen = useScreen()
    //const [docIndex, setDocIndex] = React.useState(0)
    //console.log(screen)
    let classes = React.useMemo(() => {
        let classes = className
        if (state && state.active === field) classes += " active"
        if (state && state.prev === field) classes += " prev"
        return classes
    }, [className, state, field])
    if (!value) return null
    return (
        <div ref={domRef} className={classes} {...props}>
            <Field info={info} field="img" imageStyle="hero" bg />
            <div className="content">
                <div className="content-in">
                    <div className={"slide__text o-" + screen.O}>
                        <div className="text-in">
                            <Field info={info} field="slink">
                                <Field info={info} field="section" _nowrap noadmin />
                            </Field>
                            <Field info={info} field="link">
                                <Field info={info} field="call" _nowrap noadmin />
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default React.memo(Slide)
