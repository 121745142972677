import { documentStateTypes } from "./config"

const entityFields = {
    home: [],

    NotFound: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    page: [
        {
            name: "header",
            type: "img",
            label: "Antet",
            imageStyle: "hero",
            default: [{ url: "/upload/files/2017/05/5-lg-l.jpg", w: 1920, h: 862 }],
        },
        { name: "title", type: "text", label: "Titlu" },
        { name: "cat", type: "ref", ref: "category", label: "Categorie", cache: "title,path_info" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
        { name: "docs", type: "doc", label: "Documente" },
    ],

    page2: [
        { name: "title", type: "text", label: "Titlu" },
        {
            name: "breadcrumb",
            type: "computed",
            label: "Breadcrumb",
            renderer: "Breadcrumb",
            _noadmin: true,
            _nowrap: true,
        },
        { name: "body", type: "html", label: "Conținut" },
        { name: "docs", type: "doc", label: "Documente" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
    ],
    demnitar: [
        { name: "title", type: "text", label: "Titlu" },
        {
            name: "breadcrumb",
            type: "computed",
            label: "Breadcrumb",
            renderer: "Breadcrumb",
            _noadmin: true,
            _nowrap: true,
        },
        { name: "img", type: "img", label: "Imagine" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
    ],

    document: [
        { name: "title", type: "text", label: "Titlu" },
        {
            name: "breadcrumb",
            type: "computed",
            label: "Breadcrumb",
            renderer: "Breadcrumb",
            _noadmin: true,
            _nowrap: true,
        },
        { name: "subtitle", type: "text", label: "Subtitlu", display: false },
        { name: "cat", type: "ref", ref: "category", label: "Categorie", cache: "title,path_info" },
        { name: "person", type: "select", dataSource: "personList", label: "Persoana" },
        {
            name: "state",
            type: "select",
            values: documentStateTypes,
            label: "Stare",
            renderer: "State",
            _nowrap: true,
        },
        { name: "year", type: "text", label: "An" },
        { name: "date", type: "date", label: "Data" },
        { name: "showTime", type: "bool", label: "Afișează ora" },
        { name: "body", type: "html", label: "Introducere" },
        { name: "docs", type: "doc", label: "Document" },
        { name: "special", type: "bool", label: "Special" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
    ],
    docList: [
        { name: "title", type: "text", label: "Titlu" },
        {
            name: "breadcrumb",
            type: "computed",
            label: "Breadcrumb",
            renderer: "Breadcrumb",
            _noadmin: true,
            _nowrap: true,
        },
        { name: "body", type: "html", label: "Introducere" },
        { name: "summary", type: "text", label: "Sumar" },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path_info",
            renderer: "DocList",
            _visibility: true,
        },
    ],
    folder: [
        { name: "title", type: "text", label: "Titlu" },
        {
            name: "breadcrumb",
            type: "computed",
            label: "Breadcrumb",
            renderer: "Breadcrumb",
            _noadmin: true,
            _nowrap: true,
        },
        { name: "folder", type: "computed", label: "Dosar", renderer: "Folder", _nowrap: true },
        { name: "summary", type: "text", label: "Sumar" },
    ],

    article: [
        {
            name: "header",
            type: "img",
            style: "header",
            label: "Imagine Antet",
            single: true,
            default: [{ url: "/upload/files/2017/05/7-lg-l.jpg", w: 1920, h: 862 }],
        },
        { name: "title", type: "text", label: "Titlu" },
        { name: "cat", type: "ref", ref: "category", label: "Categorie", cache: "title,path_info" },
        { name: "date", type: "date", label: "Data" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
        { name: "docs", type: "doc", label: "Documente" },
    ],

    splash: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "active", type: "bool", label: "Activ" },
        { name: "img", type: "img", label: "Imagine" },
    ],

    category: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "path_info", type: "text", label: "Pathinfo" },
    ],

    menu: [
        { name: "label", type: "text", label: "Nume" },
        { name: "items", type: "list", items: "menuItem", label: "Meniuri" },
    ],
}
export { entityFields }
