import React, { useMemo } from "react"
import { useQuery } from "../../query"
import Link from "../Link"
import EntityView from "../EntityView"
import { rowRenderers } from "conf/rowRenderer"
import Pager from "../Pager"
import { useHistory } from "../../history"

const DefaultRowRenderer = ({ info, row, index, display }) => (
    <EntityView info={info} entity={row} className="row" display={display || "viewRow"} />
)
const renderers = { default: DefaultRowRenderer, ...rowRenderers }

const initState = history => {
    const search = history.location.search
    return parseInt(search && search.page ? search.page : "1", 10)
}
const View = ({ domRef, info, value, className, ...props }) => {
    const history = useHistory()
    const [page, setPage] = React.useState(() => initState(history))

    const handlePageChange = React.useCallback(page => {
        setPage(page)
    }, [])
    const [query, config] = useMemo(() => {
        //if (!value.query) return [null, null]
        const query = {}
        const config = {}

        if (value.collection) query.collection = value.collection
        try {
            if (value.query) query.query = JSON.parse(value.query)
        } catch (e) {
            query.query = {}
            console.log(e)
        }
        if (value.projection && value.projection.trim() !== "") {
            try {
                query.projection = JSON.parse(value.projection)
                query.projection.type = 1
            } catch (e) {
                console.log(e)
            }
        }
        if (value.sort && value.sort.trim() !== "") {
            try {
                query.sort = JSON.parse(value.sort)
            } catch (e) {
                console.log(e)
            }
        }
        if (value.limit) {
            try {
                query.limit = parseInt(value.limit, 10)
            } catch (e) {
                console.log(e)
            }
        }
        if (value.pageSize) {
            try {
                query.limit = parseInt(value.pageSize, 10)
                query.skip = query.limit * (page - 1)
            } catch (e) {
                console.log(e)
            }
        }

        return [query, config]
    }, [value, page])
    //const page = 0
    //const total = 100
    const [rows, , total] = useQuery(query, config)
    const rowRenderer = value.rowRenderer || value.view
    const RowRenderer =
        rowRenderer && renderers[rowRenderer] ? renderers[rowRenderer] : renderers.default
    //console.log(value, query, rows)
    //if (!RowRenderer) return null
    const rowInfo = { ...info, display: value.display }
    return (
        <div ref={domRef} className={`${className || ""} ${value.view || ""}`} {...props}>
            {value.title && <h2 className="title">{value.title}</h2>}
            {total && value.pageSize ? (
                <Pager
                    page={page}
                    total={total}
                    pageSize={value.pageSize}
                    onChange={handlePageChange}
                />
            ) : null}

            <div className="view-content">
                {rows &&
                    RowRenderer &&
                    rows.map((row, i) => (
                        <RowRenderer
                            key={i}
                            info={rowInfo}
                            row={row}
                            index={i}
                            display={value.display}
                        />
                    ))}
            </div>
            {value.moreLink && (
                <Link to={value.moreLink} className="more-link">
                    {value.moreText || "Mai mult..."}
                </Link>
            )}
            {total && value.pageSize ? (
                <Pager
                    page={page}
                    total={total}
                    pageSize={value.pageSize}
                    onChange={handlePageChange}
                />
            ) : null}
        </div>
    )
}
export default View
