import React from "react"
import {
    useHistory,
    realPath,
    Pager,
    pagerToFirstPage,
    EntityView,
    Field,
    FaIcon,
    useQuery,
} from "lib"

const ACHIZITII_DIRECTE = "5e0cf2a1789470b55658674f" // 16
//const ANUNTURI_ACHIZITII_DIRECTE = "5e0cf2a1789470b556586750" // 19

const renderDownload = entity => {
    return (
        <div className="download-list">
            {entity.docs &&
                entity.docs.map((doc, i) => (
                    <a key={i} href={realPath(doc.url)} title={doc.name} className="download">
                        <FaIcon icon="faFileDownload" size="24" />
                    </a>
                ))}
        </div>
    )
}
const useData = state => {
    const { cat, years, page, pageSize } = state

    let qYear = {}

    try {
        qYear =
            years && years.length > 0
                ? {
                      year: {
                          $in: years
                              .map(y => (y === "NaN" ? [NaN, null, undefined] : parseInt(y, 10)))
                              .flat(),
                      },
                  }
                : {}
    } catch (e) {
        console.log(e)
    }
    const q = React.useMemo(
        () =>
            cat && cat.ref
                ? {
                      collection: "node",
                      pipeline: [
                          {
                              $match: {
                                  type: "document",
                                  "cat.ref": cat.ref,
                              },
                          },
                          { $sort: { date: -1, _id: -1 } },
                          {
                              $project: {
                                  title: 1,
                                  type: 1,
                                  path: 1,
                                  date: 1,
                                  showTime: 1,
                                  state: 1,
                                  docs: 1,
                                  cat: 1,
                                  year: { $year: "$date" },
                              },
                          },
                          { $match: qYear },
                          //{ $sort: { date: -1 } },
                          { $skip: (page - 1) * pageSize },
                          { $limit: pageSize },
                      ],
                  }
                : null,
        [cat, qYear, page, pageSize]
    )
    /*
    const q = React.useMemo(
        () =>
            cat && cat.ref
                ? {
                      collection: "node",
                      projection: {
                          title: 1,
                          type: 1,
                          path: 1,
                          date: 1,
                          showTime: 1,
                          state: 1,
                          docs: 1,
                          cat: 1,
                          year: { $year: "$date" },
                      },
                      query: {
                          type: "document",
                          "cat.ref": cat.ref,
                          ...qYear,
                      },
                      sort: { date: -1, _id: -1 },
                      skip: (page - 1) * pageSize,
                      limit: pageSize,
                  }
                : null,
        [cat, qYear, page, pageSize]
    )
    */
    //console.log(q)
    return useQuery(q)
}
/*
const useAllYears = cat => {
    const q = React.useMemo(
        () => ({
            collection: "node",
            pipeline: [
                {
                    $match: {
                        type: "document",
                        "cat.ref": cat.ref,
                    },
                },
                { $sort: { year: 1 } },
                { $project: { year: 1 } },
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: 1 },
                    },
                },
                { $sort: { _id: -1 } },
            ],
        }),
        [cat]
    )
    //console.log(q)
    return useQuery(q)
}
*/
const useAllYears = cat => {
    const q = React.useMemo(
        () => ({
            collection: "node",
            pipeline: [
                {
                    $match: {
                        type: "document",
                        "cat.ref": cat.ref,
                    },
                },
                { $sort: { date: -1 } },
                { $project: { year: { $year: "$date" } } },
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: 1 },
                    },
                },
                { $sort: { _id: -1 } },
            ],
        }),
        [cat]
    )
    //console.log(q)
    return useQuery(q)
}
const searchRemoveYear = search =>
    search
        ? Object.keys(search)
              .filter(key => key !== "year")
              .reduce((acc, key) => ({ ...acc, [key]: search[key] }), {})
        : null

const initYearFilter = history =>
    history.location.search.year ? history.location.search.year.split(",") : []
const YearFilter = ({ history, cat, onChange }) => {
    const [allYears] = useAllYears(cat)
    const [years, setYears] = React.useState(() => initYearFilter(history))
    const handleYearToggle = React.useCallback(
        e => {
            const year = e.currentTarget.dataset.year
            let ys = years
            if (years.includes(year)) ys = years.filter(y => y !== year)
            else ys = [...years, year]
            if (ys.length === 0) {
                history.pushSearch({
                    ...pagerToFirstPage(searchRemoveYear(history.location.search)),
                })
            } else {
                history.pushSearch({ ...pagerToFirstPage(history.location.search), year: [ys] })
            }
            setYears(ys)
            onChange(ys)
        },
        [years, history, onChange]
    )
    if (!allYears) return null
    return (
        <div className="year-filter">
            {allYears.map(year => (
                <div
                    key={year._id}
                    data-year={isNaN(year._id) || !year._id ? "NaN" : year._id}
                    onClick={handleYearToggle}
                >
                    <FaIcon
                        icon={
                            years.includes(`${isNaN(year._id) || !year._id ? "NaN" : year._id}`)
                                ? "toggle/check_box"
                                : "toggle/check_box_outline_blank"
                        }
                        size={24}
                    />
                    <div className="year">
                        {isNaN(year._id) || !year._id ? "Fără dată" : year._id}
                    </div>
                    <div className="count">({year.count})</div>
                </div>
            ))}
        </div>
    )
}
const types = {
    pdf: "regular/faFilePdf",
    p7s: "regular/faFileArchive",
    doc: "regular/faFileWord",
    docx: "regular/faFileWord",
    xls: "regular/faFileExcel",
    xlsx: "regular/faFileExcel",
    jpg: "regular/faFileImage",
    png: "regular/faFileImage",
}
const getIcon = docs => {
    if (!docs || docs.length === 0) return "regular/faFile"
    const doc = docs[0]
    const ext = doc.url
        .split(".")
        .pop()
        .toLowerCase()
    //if (!types[ext]) console.log(doc, ext)
    return types[ext] || "regular/faFileAlt"
}
const initState = (cat, history) => {
    const search = history.location.search
    const page = parseInt(search && search.page ? search.page : "1", 10)
    const pageSize = 10
    return {
        cat,
        years: search.year ? search.year.split(",") : [],
        page,
        pageSize,
    }
}
const DocList = ({ domRef, info, value, ...props }) => {
    //console.log(value)
    const history = useHistory()
    const [state, setState] = React.useState(() => initState(value, history))
    const [data, , total] = useData(state)
    const handleYearChange = React.useCallback(years => {
        setState(state => ({ ...state, years }))
    }, [])
    const handlePageChange = React.useCallback(page => {
        setState(state => ({ ...state, page }))
    }, [])
    //console.log(data, total)
    if (!data) return null

    return (
        <div ref={domRef} {...props}>
            <YearFilter history={history} cat={value} onChange={handleYearChange} />
            <Pager
                page={state.page}
                total={total}
                pageSize={state.pageSize}
                onChange={handlePageChange}
            />
            {data.map((e, i) => (
                <EntityView info={info} entity={e} key={i} className="row" display="simple">
                    {info => (
                        <>
                            <FaIcon icon={getIcon(info.entity.docs)} size={32} />
                            <Field info={info} field="state" />
                            <div className="row-in">
                                {e.date && <Field info={info} field="date" />}
                                <Field info={info} field="title" />
                            </div>
                            {value.ref === ACHIZITII_DIRECTE && renderDownload(e)}
                        </>
                    )}
                </EntityView>
            ))}
            <Pager
                page={state.page}
                total={total}
                pageSize={state.pageSize}
                onChange={handlePageChange}
            />
        </div>
    )
}
export default React.memo(DocList)
