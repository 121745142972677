import React from "react"
import { Link } from "lib"

const LinkRenderer = ({ domRef, info, value, children, ...props }) => (
    <Link domRef={domRef} {...props} to={value}>
        {children}
    </Link>
)

export default LinkRenderer
