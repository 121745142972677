import axios from "axios"
import React, { useReducer, useEffect } from "react"
//import C from "../../../conf"
import Entity from "../../../entity"

const sendForm = (form, cb = null) => {
    return axios.post("form", form).then(response => {
        if (cb) cb(response)
        return response
    })
    //.catch(error => console.log(error))
}

export const FormContext = React.createContext({})
const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case "change": {
            const { type, field, ...data } = action
            return { ...state, error: null, data: { ...state.data, [action.field]: data } }
        }
        case "submit":
            const error = Object.keys(state.formConfig).reduce((acc, key) => {
                if (acc) return acc
                if (state.formConfig[key].mandatory && !state.data?.[key]?.value)
                    return (
                        <>
                            Rubrica <strong>{state.formConfig[key].label}</strong> este obligatorie.
                        </>
                    )
                return false
            }, false)
            if (error) return { ...state, error, state: "open" }
            return { ...state, error: null, state: "readyToSend" }
        case "error":
            return { ...state, error: action.error, state: "open" }
        default:
            return state
    }
}
/*
view states: open / readyToSend / sending / sent ok / send error / already sent
action:send => state: readyToSend
effect => state: sending

*/
const Form = ({ domRef, info, value, children, ...props }) => {
    const context = useReducer(reducer, {
        formID: `${Entity.getId(info.entity)}:${info.fieldName}`,
        data: {},
        state: info.state || "open",
        formConfig: value,
    })
    const [state, dispatch] = context

    useEffect(() => {
        if ((!info.state || info.state === "open") && state.state === "readyToSend") {
            info.setState("sending")
            console.log(state.data)
            sendForm({
                form: value.name,
                data: state.data,
                url: document.location.pathname,
                lang: info.language,
            })
                .then(response => {
                    //console.log(response)
                    if (!response || !response.data) {
                        dispatch({ type: "error", error: "Răspuns necunoscut." })
                        info.setState("open")
                        return
                    }
                    if (response.data.error) {
                        dispatch({ type: "error", error: response.data.error })
                        info.setState("open")
                        return
                    }
                    info.setState("sent")
                })
                .catch(error => {
                    console.log(error)
                    dispatch({ type: "open" })
                    info.setState("open")
                })
        }
    }, [info, context, dispatch, state, value])

    if (info.fieldInfo._inner) {
        return (
            <div ref={domRef} {...props}>
                <div inner="">
                    {state.error && <div className="error">{state.error}</div>}
                    <FormContext.Provider value={context}>{children}</FormContext.Provider>
                </div>
            </div>
        )
    }
    return (
        <div ref={domRef} {...props}>
            {state.error && <div className="error">{state.error}</div>}
            <FormContext.Provider value={context}>{children}</FormContext.Provider>
        </div>
    )
}
export default Form
