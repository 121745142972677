import React, { Component } from "react"
import ReactDOM from "react-dom"
import TransitionGroup from "react-transition-group/TransitionGroup"
import CSSTransition from "react-transition-group/CSSTransition"
import FaIcon from "./FaIcon"

class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            height: "80vh",
        }
        this.ref = React.createRef()
    }

    componentDidMount() {
        document.body.classList.add("overlay-open")
        setTimeout(() => this.resize(true), 900)
    }
    componentWillUnmount() {
        document.body.classList.remove("overlay-open")
        this.unmounted = true
    }
    componentDidUpdate(prevProps) {
        if (this.unmounted) return
        if (this.props.requestClose && !prevProps.requestClose) this.onClose()
        setTimeout(() => this.resize(true), 900)
        //this.resize(true)
    }
    resize = () => {
        const { type } = this.props

        if (this.unmounted) return
        if (type !== undefined) return
        const mc = document.querySelectorAll(".modal-content").item(0)
        if (!mc) return
        const elems = mc.childNodes
        let height = 0
        elems.forEach(elem => {
            const h = elem.getBoundingClientRect().height
            if (h > height) height = h
        })
        if (height > 0.9 * window.innerHeight) height = 0.9 * window.innerHeight
        if (height !== this.state.height) {
            this.setState({ height })
        }
    }

    onClose = () => {
        this.setState({ open: false })
    }
    onExited = () => {
        if (this.props.onClose) this.props.onClose()
    }

    render() {
        const { name, type, w, h, closeButton } = this.props
        const { open, height } = this.state

        const classes = "modal modal-" + name + " " + (type || "normal")
        const style = type === undefined ? { height } : {}
        if (w) style["width"] = w + "px"
        if (h) style["height"] = h + "px"
        //const closeText = this.props.closeText || "Închide"
        const Close = closeButton
              //|| (() => closeText)

        return ReactDOM.createPortal(
            <TransitionGroup className={`modal-${name}-container`}>
                {open && (
                    <CSSTransition
                        key={name}
                        in={true}
                        classNames="modal"
                        timeout={900}
                        appear={true}
                        onExited={this.onExited}
                    >
                        <div ref={this.ref} className={classes}>
                            <div className="modalbg" onClick={this.onClose} />
                            <div className="modal-content" style={style}>
                                {this.props.children}
                            </div>
                            {type !== undefined && (
                                <div className="close" onClick={this.onClose}>
                                    {Close ? (
                                        <Close />
                                    ) : (
                                        closeButton || <FaIcon icon="faTimes" />
                                    )}
                                </div>
                            )}
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>,
            document.getElementById("modal-container")
        )
    }
}

export default Modal
