import React from "react"
import { LazyImage } from ".."
import { renderVideo } from "../../util"
//import renderRaw from './raw'
const styleTag = {
    BOLD: "strong",
    ITALIC: "em",
    SUP: "sup",
    SUB: "sub",
}
const styleMap = {
    BOLD: { fontWeight: "bold" },
    ITALIC: { fontStyle: "italic" },
    UNDERLINE: { fontStyle: "underline" },
}

const getStyle = key => {
    if (styleMap[key]) return styleMap[key]

    if (key.startsWith("COLOR")) {
        styleMap[key] = { color: key.substr(5) }
        return styleMap[key]
    }
    if (key.startsWith("BG")) {
        styleMap[key] = { backgroundColor: key.substr(2) }
        return styleMap[key]
    }
    if (key.startsWith("FS")) {
        styleMap[key] = { fontSize: key.substr(2) }
        return styleMap[key]
    }
    if (key.startsWith("FF")) {
        styleMap[key] = { fontFamily: key.substr(2) }
        return styleMap[key]
    }
    return {}
}

const blockTag = {
    unstyled: "p",
    "header-one": "h1",
    "header-two": "h2",
    "header-three": "h3",
    "header-four": "h4",
    "header-five": "h5",
    "header-six": "h6",
}

const elementAddRange = (el, range) => {
    if (range.offset > el.offset + el.length || range.offset + range.length < el.offset) return
    if (range.offset <= el.offset && range.offset + range.length >= el.offset + el.length) {
        //console.log(el, range)
        if (range.style) {
            if (el.styles) el.styles.push(range.style)
            else el.styles = [range.style]
        } else {
            if (el.keys) el.keys.push(range.key)
            else el.keys = [range.key]
        }
        return
    }
    if (el.children) {
        el.children.forEach(child => elementAddRange(child, range))
        return
    }

    if (range.offset <= el.offset) {
        el.children = [
            {
                text: el.text,
                offset: el.offset,
                length: range.offset + range.length - el.offset,
                styles: range.style ? [range.style] : null,
                keys: range.key !== undefined ? [range.key] : null,
            },
            {
                text: el.text,
                offset: range.offset + range.length,
                length: el.offset + el.length - range.offset - range.length,
            },
        ]
        return
    }
    if (range.offset + range.length >= el.offset + el.length) {
        el.children = [
            {
                text: el.text,
                offset: el.offset,
                length: range.offset - el.offset,
            },
            {
                text: el.text,
                offset: range.offset,
                length: el.offset + el.length - range.offset,
                styles: range.style ? [range.style] : null,
                keys: range.key !== undefined ? [range.key] : null,
            },
        ]
        return
    }
    el.children = [
        {
            text: el.text,
            offset: el.offset,
            length: range.offset - el.offset,
        },
        {
            text: el.text,
            offset: range.offset,
            length: range.length,
            styles: range.style ? [range.style] : null,
            keys: range.key !== undefined ? [range.key] : null,
        },
        {
            text: el.text,
            offset: range.offset + range.length,
            length: el.offset + el.length - range.offset - range.length,
        },
    ]
}
const treeAddBlock = (tree, block, entityMap, prev) => {
    if (block.type === "unstyled" && block.text === "") {
        if (prev && prev.type === "atomic") return
        //block.text = '\xa0'
    }

    const length = block.text.length
    const el = {
        key: block.key,
        style: {},
        offset: 0,
        length,
        text: block.text,
    }
    if (block.type === "unstyled" && block.text === "") {
        el.style = { margin: 0 }
    }
    if (blockTag[block.type]) el.tag = blockTag[block.type]
    block.inlineStyleRanges.forEach(range => {
        if (range.offset === 0 && range.length === length) {
            el.style = Object.assign({}, el.style, getStyle(range.style))
            return
        }
        elementAddRange(el, range)
    })
    block.entityRanges.forEach(range => elementAddRange(el, range))
    tree.push(el)
}
const buildTree = raw => {
    const { blocks, entityMap } = raw
    const tree = []
    //console.log(raw)
    let prev = null
    blocks.forEach(block => {
        treeAddBlock(tree, block, entityMap, prev)
        prev = block
    })
    //console.log(tree)
    return tree
}

const LinkEntity = ({ data, children }) => <a href={data.url}>{children}</a>

const ImgEntity = ({ data }) => {
    const { img, width, align, float } = data
    let wrapperStyles = {}
    let styles = {}
    if (width) styles.width = `${width}%`
    else styles.width = "100%"
    if (align) {
        if (float) {
            if (align === "left" || align === "right") styles.float = align
            else {
                wrapperStyles.display = "flex"
                wrapperStyles.justifyContent = "center"
                wrapperStyles.alignItems = "flex-start"
            }
        } else {
            let justify = ""
            if (align === "left") justify = "flex-start"
            if (align === "center") justify = "center"
            if (align === "right") justify = "flex-end"
            wrapperStyles.display = "flex"
            wrapperStyles.justifyContent = justify
            wrapperStyles.alignItems = "flex-start"
        }
    }
    if (Object.keys(wrapperStyles).length > 0) {
        return (
            <figure style={wrapperStyles}>
                <LazyImage src={img} alt="" style={styles} imageStyle="img" />
            </figure>
        )
    } else {
        return <LazyImage src={img} alt="" style={styles} imageStyle="img" />
    }
}
const EmbedEntity = ({ data }) => renderVideo(data.url)

const entityRenderer = {
    LINK: LinkEntity,
    image: ImgEntity,
    embed: EmbedEntity,
}
const renderText = (text, el) => {
    //console.log(text, text.length, el)
    if (text === "" && el.tag === "p") return <br />
    const chunks = text.split("\n")
    return chunks.map((chunk, i) => (
        <React.Fragment key={i}>
            {chunk}
            {i < chunks.length - 1 && <br />}
        </React.Fragment>
    ))
}
const renderElement = (el, i, entityMap) => {
    const tags = []
    let style = el.style || {}
    if (el.tag) tags.push({ Tag: el.tag, i, props: { style } })
    //console.log(el.styles)
    if (el.styles)
        el.styles.forEach(label => {
            if (styleTag[label]) {
                if (tags.length === 0 && Object.keys(style).length > 0) {
                    tags.push({ Tag: "span", i, props: { style } })
                }
                tags.push({ Tag: styleTag[label], i })
                style = {}
            } else {
                if (
                    tags.length === 0 ||
                    !tags[tags.length - 1].props ||
                    !tags[tags.length - 1].props.style
                ) {
                    tags.push({ Tag: "span", i, props: { style } })
                }
                const tag = tags[tags.length - 1]
                tag.props.style = Object.assign({}, tag.props.style, getStyle(label))
            }
        })
    if (tags.length === 0 && Object.keys(style).length > 0)
        tags.push({ Tag: "span", i, props: { style } })

    if (el.keys)
        el.keys.forEach(key => {
            if (!entityMap[key]) return
            const { type, data } = entityMap[key]
            if (!entityRenderer[type]) return
            tags.push({ Tag: entityRenderer[type], i, props: { data } })
        })
    //console.log(tags)
    return tags.reduce(
        (acc, { Tag, i, props }) => (
            <Tag key={i} {...props}>
                {acc}
            </Tag>
        ),
        el.children
            ? el.children.map((child, i) => renderElement(child, i, entityMap))
            : renderText(el.text.substr(el.offset, el.length), el)
    )
}
const renderRaw = raw => {
    //console.log(raw)
    const tree = buildTree(raw)
    return tree.map((el, i) => renderElement(el, i, raw.entityMap))
}

const RawDraft = ({ info, value }) => {
    //console.log('render HTML', field, 'in', entity.type)
    if (!value) return null
    if (typeof value === "string") {
        return <div className="field-inner" dangerouslySetInnerHTML={{ __html: value }} />
    }
    if (value.raw.blocks.length === 1 && value.raw.blocks[0].text === "") return null
    if (info.fieldInfo && info.fieldInfo.wrapper)
        return <div className="field-inner">{renderRaw(value.raw)}</div>
    return renderRaw(value.raw)
}
export default RawDraft
