import { isString, splitOn } from "./string"
import { head, tail, init, last } from "./list"
import { isJust, isNothing } from "./maybe"

export default {
    // string functions
    isString,
    splitOn,
    // array/list functions
    head,
    tail,
    init,
    last,
    // maybe
    isJust,
    isNothing,
}
