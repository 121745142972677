import React from "react"
import { Field, FaIcon } from "lib"

const IconRenderer = ({ domRef, info, value, ...props }) => {
    if (!value) return null

    return (
        <div ref={domRef} {...props}>
            {value.img && value.img.length > 0 && (
                <Field info={info} field="img" imageStyle="icon" noadmin />
            )}
            {value.icon && <FaIcon icon={value.icon} />}
            <Field info={info} field="text" noadmin />
        </div>
    )
}
export default React.memo(IconRenderer)
