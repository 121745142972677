import React from "react"
import { Field } from "lib"
//const known_types = ["pdf", "doc", "docx", "xls", "xlsx"]
//const img_types = ["jpg", "png", "gif"]

const Action = ({ domRef, info, value, children, ...props }) => {
    if (!value) return null
    return (
        <div ref={domRef} {...props}>
            <Field info={info} field="link" noadmin>
                <Field info={info} field="call" _nowrap />
            </Field>
        </div>
    )
}
export default React.memo(Action)
