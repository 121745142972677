import React from "react"
import { Region } from "lib"
//import PageLayout from "./PageLayout"
import Siblings from "./Siblings"

export const DefaultLayout = ({ entity, language }) => (
    <Region entity={entity} language={language} dynamic />
)
/*
  <Region region="hero" entity={entity} language={language}>
  <Siblings entity={entity} />
  </Region>
*/
export const PageLayout = ({ entity, language }) => {
    //console.log(entity)
    return (
        <>
            <Region region="hero" entity={entity} language={language} />

            <div region-node-main="">
                <Region entity={entity} language={language} dynamic />
                <div region-right="">
                    <Siblings entity={entity} />
                </div>
            </div>
        </>
    )
}
const HomeLayout = ({ entity, language }) => <Region entity={entity} language={language} dynamic />

export const nodeLayout = {
    home: HomeLayout,
    page: PageLayout,
    article: PageLayout,
    NotFound: HomeLayout,
}
