import { Entity } from "lib"
import { documentStateTypes } from "./config"
const DECLARATII_DE_AVERE = "9" // 16
const DECLARATII_DE_INTERESE = "10" // 16
const ACHIZITII_DIRECTE = "5e0cf2a1789470b55658674f" // 16
const ANUNTURI_ACHIZITII_DIRECTE = "5e0cf2a1789470b556586750" // 19
const _handleChangeState = (entity, state) => () => {
    const e = Entity.set(entity, "state", state.val)
    Entity.save(e)
}
export const nodeTypes = {
    home: { name: "Acasă", _noCreate: true },
    folder: {
        name: "Dosar",
        info: "Dosar",
        searchField: "title",
        //_noCreate: true,
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    page: {
        name: "Pagină prezentare",
        info: "Pagină generică",
        searchField: "title",
        //_noCreate: true,
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    page2: {
        name: "Pagină(2)",
        info: "Pagină generică",
        searchField: "title",
        //_noCreate: true,
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    demnitar: {
        name: "Demnitar",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },

    document: {
        name: "Document",
        info: "Document",
        searchField: "title",
        //_noCreate: true
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        pathinfo: entity => (entity.cat && entity.cat.path_info ? entity.cat.path_info : ""),
        presave: entity => {
            //const types = documentTypes.filter(type => type.val === entity.kind)
            //if (!entity._c) entity._c = {}
            //entity._c.pathinfo = types.length > 0 ? types[0].pathinfo : null
            if (
                entity.cat &&
                (entity.cat.ref === ACHIZITII_DIRECTE ||
                    entity.cat.ref === ANUNTURI_ACHIZITII_DIRECTE)
            ) {
                try {
                    const d = new Date(entity.date)
                    entity.year = d.getFullYear()
                } catch (e) {}
            }
            return entity
        },
        conditional: {
            showTime: entity => {
                let e = entity
                if (entity.showTime) e = Entity.updateSettings(e, "date", { showTime: true })
                else e = Entity.updateSettings(e, "date", { showTime: false })
                Entity.resetInfo(e)
                return e
            },
            cat: entity => {
                let e = entity
                //console.log(entity)
                if (!entity.cat || !entity.cat.ref) return entity
                switch (entity.cat.ref) {
                    case DECLARATII_DE_AVERE:
                    case DECLARATII_DE_INTERESE:
                        e = Entity.updateSettings(e, "year", { _hidden: false })
                        e = Entity.updateSettings(e, "person", { _hidden: false })
                        e = Entity.updateSettings(e, "state", { _hidden: true })
                        break
                    case ACHIZITII_DIRECTE: // achizitii directe
                        e = Entity.updateSettings(e, "year", { _hidden: true })
                        e = Entity.updateSettings(e, "state", { _hidden: false })
                        e = Entity.updateSettings(e, "person", { _hidden: true })
                        break
                    default:
                        e = Entity.updateSettings(e, "year", { _hidden: true })
                        e = Entity.updateSettings(e, "state", { _hidden: true })
                        e = Entity.updateSettings(e, "person", { _hidden: true })
                }
                Entity.resetInfo(e)
                return e
            },
        },

        contextMenu: (user, entity) =>
            entity.cat && entity.cat.ref === ACHIZITII_DIRECTE
                ? documentStateTypes.map(state => ({
                      label: state.label,
                      radio: true,
                      checked: entity.state === state.val,
                      handler: _handleChangeState(entity, state),
                  }))
                : [],
    },
    docList: {
        name: "Lista documente",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    article: {
        name: "Articol",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        pathinfo: entity => (entity.cat && entity.cat.path_info ? entity.cat.path_info : ""),
    },
    NotFound: {
        name: "Pagină inexistentă",
        _noCreate: true,
    },
}

export const entityTypes = {
    menu: {
        name: "Meniu",
        collection: "menu",
        getLabel: e => e.label,
        _noCreate: true,
    },
    splash: {
        name: "Splash",
        collection: "splash",
    },
    category: {
        name: "Categorie",
        collection: "cat",
        searchField: "title",
    },
}
