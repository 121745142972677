import React from "react"
import { useQuery, EntityView } from "lib"
import { useMainMenu } from "../header/MainMenu"

const getChildren = (menu, path) => {
    for (let i = 0; i < menu?.length; i++) {
        if (menu[i].p === path) return menu[i].items
        if (path.indexOf(menu[i].p) === 0) return getChildren(menu[i].items, path)
    }
    return null
}

const useFolder = entity => {
    const mainMenu = useMainMenu()
    const q = React.useMemo(() => {
        if (!entity) return null
        return {
            query: { path: { $regex: `^${entity.path}/[^/]+$` } },
            projection: {
                title: 1,
                path: 1,
                summary: 1,
                subtitle: 1,
                thumb: 1,
                type: 1,
            },
        }
    }, [entity])
    //console.log(q)
    const [results] = useQuery(q)
    const folder = React.useMemo(() => {
        if (mainMenu && results) {
            const items = getChildren(mainMenu, entity.path)
            //console.log(items, results)
            if (items) {
                let folder = []
                for (let i = 0; i < items.length; i++) {
                    const item = items[i]
                    for (let j = 0; j < results.length; j++) {
                        if (item.p === results[j].path) {
                            folder.push(results[j])
                            break
                        }
                    }
                }
                return folder
            }
            //return sort(mainMenu, results)
        }
        return results || []
    }, [mainMenu, results, entity])
    return folder
}

const Folder = ({ domRef, info, value, ...props }) => {
    const folder = useFolder(info.entity)
    //console.log(folder)

    return (
        <div ref={domRef} {...props}>
            {folder.map((e, i) => (
                <EntityView info={info} display="teaser" entity={e} key={i} />
            ))}
        </div>
    )
}
export default React.memo(Folder)
