import icons from "./icons"

const svgIcons = Object.keys(icons).map(icon => ({ val: icon, label: icon }))

const textFromTextNode = node =>
    node.hasOwnProperty("text") ? node.text : node.leaves.reduce((acc, leaf) => acc + leaf.text, "")

const htmlNodeGetText = node =>
    node && node.nodes
        ? node.nodes.reduce(
              (acc, node) =>
                  acc + (node.object === "text" ? textFromTextNode(node) : htmlNodeGetText(node)),

              ""
          )
        : ""

const htmlIsEmpty = value =>
    !value ||
    (typeof value === "string"
        ? value === "" || value === "<p></p>"
        : !value.document || htmlNodeGetText(value.document).trim() === "")

const isListEmpty = value => !value || value.length === 0
const fieldTypes = {
    img: {
        arrowRenderers: [icons.ArrowLeft, icons.ArrowRight],
        isEmpty: isListEmpty,
    },
    text: {
        name: "Text simplu",
        //dynamic: false,
        isEmpty: value => !value || (typeof value === "string" && value.trim() === ""),
    },
    html: {
        name: "Text",
        isEmpty: htmlIsEmpty,
    },
    bool: { dynamic: false },
    class: { dynamic: false },
    radio: { dynamic: false },
    list: { dynamic: false },
    obj: { dynamic: false },
    custom: { dynamic: false },
    doc: {
        isEmpty: isListEmpty,
    },
    href: {
        name: "Legătură simplă",
        type: "text",
        isEmpty: value => !value || value === "",
        _nowrap: true,
        renderer: "Link",
    },
    link: {
        name: "Legătură",
        type: "obj",
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "path", type: "text", label: "Legătură" },
        ],
        isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "LinkRenderer",
    },

    block: {
        name: "Container",
        type: "obj",
        isBlock: true,
        _nowrap: true,
        fields: [],
        settings: [
            { name: "_o", type: "bool", label: "Orizontal", widget: "toggle" },
            { name: "bg", type: "img", label: "Imagine" },
            { name: "p", type: "bool", label: "Proporțional", widget: "toggle" },
            { name: "f", type: "bool", label: "Fullscreen fixed", widget: "toggle" },
        ],
        renderer: "Block",
    },
    icon: {
        name: "Icon",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "icon", type: "select", values: svgIcons, label: "Iconiță" },
            { name: "img", type: "img", label: "Icon", single: true },
            { name: "big", type: "bool", label: "Text mare", widget: "toggle" },
            { name: "text", type: "html", label: "Text" },
        ],
        renderer: "Icon",
    },
    quote: {
        name: "Citat",
        type: "obj",
        fields: [
            { name: "content", type: "html", label: "Citat" },
            { name: "source", type: "text", label: "Sursa", fullWidth: true },
        ],
        renderer: "Quote",
    },
    menuItem: {
        name: "Item",
        dynamic: false,
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "p", type: "text", label: "Cale" },
            { name: "items", type: "list", items: "menuItem", label: "Submeniu" },
        ],
    },
    slideshow: {
        name: "Slideshow",
        type: "obj",
        isBlock: true,
        _nowrap: true,
        fields: [],
        stateful: true,
        renderer: "Slideshow",
    },
    slide: {
        name: "Slide",
        type: "obj",
        _nowrap: true,
        fields: [
            {
                name: "img",
                type: "img",
                label: "Imagine",
                single: false,
            },
            {
                name: "section",
                type: "text",
                label: "Secţiune",
            },
            {
                name: "slink",
                type: "href",
                label: "Legătură Secţiune",
            },
            {
                name: "call",
                type: "text",
                label: "Chemare",
            },
            {
                name: "link",
                type: "href",
                label: "Legătură",
            },
        ],
        renderer: "Slide",
    },
    action: {
        name: "Action",
        type: "obj",
        _nowrap: true,
        fields: [
            {
                name: "icon",
                type: "text",
                label: "Iconiță",
            },
            {
                name: "call",
                type: "text",
                label: "Chemare",
                t: true,
            },
            {
                name: "link",
                type: "href",
                label: "Legătură",
                t: true,
                default: "#",
            },
        ],
        renderer: "Action",
    },
    card: {
        name: "Card",
        type: "obj",
        fields: [
            { name: "icon", type: "text", label: "Iconiță", renderer: "SimpleIcon" },
            { name: "title", type: "text", label: "Titlu" },
            { name: "text", type: "html", label: "Text" },
        ],
        renderer: "Card",
    },
    cardList: {
        name: "CardList",
        type: "list",
        items: "card",
        renderer: "CardList",
        //_nowrap: true,
    },
    minicard: {
        name: "MiniCard",
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu", tag: "h2" },
            { name: "icon", type: "text", label: "Iconiță", renderer: "SimpleIcon" },
            { name: "name", type: "text", label: "Nume", tag: "h3" },
            {
                name: "phone",
                type: "text",
                label: "Telefon",
                iconBefore: { icon: "faPhone", size: 14 },
            },
            {
                name: "mail",
                type: "text",
                label: "E-mail",
                iconBefore: { icon: "faEnvelope", size: 14 },
            },
        ],
        renderer: "Minicard",
    },
    minicardList: {
        name: "MiniCardList",
        type: "list",
        items: "minicard",
        renderer: "MinicardList",
        //_nowrap: true,
    },
}

export { fieldTypes }
