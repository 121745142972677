import React from "react"
import Entity from "../../../entity"

const FormFieldTextarea = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const handleChange = e => {
        //console.log(e.currentTarget.value)
        dispatch({
            type: "change",
            field: info.field,
            label: value.label,
            fieldType: value.fieldType,
            value: e.currentTarget.value,
        })
    }
    return (
        <div ref={domRef} {...props}>
            <textarea
                name={info.field}
                type="text"
                value={fieldValue || ""}
                placeholder={Entity.get(value, "label", info.language)}
                onChange={handleChange}
            />
        </div>
    )
}
export default FormFieldTextarea
