import React from "react"
import { Link, realPath } from "lib"
import MainMenu, { useMainMenu } from "./MainMenu"
import MobileMenu from "./MobileMenu"

const Header = ({ node, language, first }) => {
    const menu = useMainMenu()
    return (
        <header role="banner" id="header" key="header">
            <Link id="logo" to="/">
                <img src={realPath("/img/logo.png")} alt="Logo" />
                <div className="site-name">
                    <div className="line1">Primăria Comunei</div>
                    <div className="line2">Telciu</div>
                    <div className="line3">Bistrița-Năsăud</div>
                </div>
            </Link>

            <div className="header-in">
                <MainMenu id="main-menu" menu={menu} language={language} />
                <MobileMenu menu={menu} />
            </div>
        </header>
    )
}
export default React.memo(Header)
