import React from "react"
import { Field } from "lib"

const Card = ({ domRef, info, value, ...props }) => {
    if (!value) return null
    //console.log(value, props)
    return (
        <>
            <div className="header">
                <Field info={info} field="icon" />
                <Field info={info} field="title" />
            </div>
            <Field info={info} field="text" />
        </>
    )
}
export default React.memo(Card)
