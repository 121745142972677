import React from "react"
import axios from "axios"
import { useUnmounted } from "lib"
const cache = {}
const useIcon = icon => {
    const [iconData, setIconData] = React.useState(() => cache[icon])
    const unmounted = useUnmounted()
    React.useEffect(() => {
        try {
            if (unmounted.current) return
            if (cache[icon]) {
                if (cache[icon] instanceof Promise) {
                    cache[icon]
                        .then(response => {
                            if (unmounted.current) return
                            setIconData(response.data)
                        })
                        .catch(e => {
                            console.log(`Error loading SVG ${icon}`, e)
                        })
                    return
                }
                setIconData(cache[icon])
                return
            }
            const p = axios.get(`svg/${icon}`)
            cache[icon] = p
            p.then(response => {
                cache[icon] = response.data
                if (unmounted.current) return
                setIconData(cache[icon])
            }).catch(e => {
                console.log(`Error loading SVG ${icon}`, e)
            })
        } catch (e) {
            console.log(e)
        }
    }, [unmounted, icon])
    return iconData
}

const FaIcon = ({ icon, size, ...props }) => {
    const iconData = useIcon(icon)
    if (!iconData || iconData instanceof Promise) return null
    const [width, height, , , path] = iconData
    let style = {}
    if (size) {
        style = {
            width: `${size}px`,
            height: `${size}px`,
        }
    }

    return (
        <svg
            style={style}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill="currentColor" d={path} />
        </svg>
    )
}
export default FaIcon
