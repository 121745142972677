import React from "react"
import { Helmet } from "react-helmet-async"
import { C, realPath, Delayed, Entity } from "lib"

const locales = {
    ro: "ro_RO",
    en: "en_US",
    de: "de_DE",
}
const SEO = ({ node, language }) => {
    const url = typeof window === "undefined" ? `${C.BASE}${global.location}` : window.location.href

    const title = C.WEBSITE_NAME + (node && node.title ? ` - ${node.title}` : "")
    const seoTitle = Entity.get(node, "_seo.title") || title

    const locale = locales[language] || "ro_RO"
    const description = Entity.get(node, "_seo.description") || ""
    const thumb = Entity.get(node, "_seo.image") || Entity.get(node, "thumb")
    const image = thumb && thumb.length > 0 ? thumb[0].url : null
    let meta = [
        { name: "og:url", content: url },
        { name: "og:type", content: "article" },
        { name: "og:title", content: seoTitle },
        { name: "og:description", content: description },
        { name: "og:locale", content: locale },
        { name: "twitter:url", content: url },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
    ]
    if (image)
        meta.push({
            name: "og:image",
            content: realPath(image, "thumb"),
        })
    return (
        <Delayed key={url} maxDelay={5000}>
            <Helmet title={title} meta={meta} />
        </Delayed>
    )
}
export default SEO
