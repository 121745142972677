import {
    buildFieldInfo,
    getInfo,
    resetInfo,
    getFields,
    getFieldInfo,
    getCollection,
    is,
    getDisplayInfo,
    getFieldDisplayInfo,
} from "./info"
import { getType, entityTypes, getTypeInfo } from "./types"
import { fieldTypes } from "./fieldTypes"
import { addField } from "./addField"
import { get, set, unset } from "./accessors"
import { create, load, save, isNew, getId, remove } from "./entity"
import { setSettings, updateSettings } from "./settings"

export default {
    set,
    unset,
    get,
    getInfo,
    is,
    getDisplayInfo,
    getFieldDisplayInfo,
    resetInfo,
    getFields,
    getFieldInfo,
    buildFieldInfo,
    entityTypes,
    getType,
    getTypeInfo,
    getCollection,
    fieldTypes,
    addField,
    create,
    load,
    save,
    remove,
    isNew,
    getId,
    setSettings,
    updateSettings,
}
