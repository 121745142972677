import React, { useRef } from "react"
import Field from "../Field"

const PullQuote = ({ info, attachment }) => {
    const ref = useRef()
    return (
        <aside ref={ref} className={attachment._class}>
            <Field info={info} field={attachment.field} className="attachment" />
        </aside>
    )
}
export default PullQuote
