import React from "react"
import { AuthContext } from "../auth"
import FaIcon from "./FaIcon"

const AuthButton = () => (
    <div className="auth">
        <AuthContext.Consumer>
            {({ user, login, logout }) =>
                user ? (
                    <div onClick={logout}>
                        <FaIcon icon="faLockOpen" />
                    </div>
                ) : (
                    <div onClick={login}>
                        <FaIcon icon="faLock" />
                    </div>
                )
            }
        </AuthContext.Consumer>
    </div>
)
export default AuthButton
