import axios from "axios"
//import wrapInfo from "../conf/wrapInfo"
//import { query, insert, update, queryRefreshAll } from "./api"
import { History } from "../history"
import { Query } from "../query"
import C from "../conf"
//import fieldAdmin from "../conf/fieldAdmin"
import { entityTypes, getTypeInfo } from "./types"
import { get, set } from "./accessors"
import { getFieldInfo, getInfo, getFields, getCollection, is } from "./info"
import customFieldAdmin from "conf/fieldAdmin"
import { ObjectId } from "bson"

export const isNew = e => e && e._id && !e._custom && !(e._id instanceof ObjectId)
export const getId = e => (e && e._id && e._id instanceof ObjectId ? e._id.toString() : "")
//export const isEditable = e => e && e._id && e._id instanceof ObjectId

const fieldAdmin = {}
Object.keys(customFieldAdmin).forEach(key => {
    if (fieldAdmin[key]) fieldAdmin[key] = { ...fieldAdmin[key], ...customFieldAdmin[key] }
    else fieldAdmin[key] = customFieldAdmin[key]
})
function* refGenerator(entity, parentFieldName, parent, parentFieldInfo, fields) {
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const value = parent ? get(parent, field.name) : get(entity, field.name)
        const fieldName = parentFieldName ? `${parentFieldName}.${field.name}` : field.name
        if (value) {
            if (field.type === "ref") yield [field, value, fieldName]
            else if (field.fields)
                yield* refGenerator(entity, fieldName, value, field, field.fields)
            else if (field.type === "list") {
                for (let j = 0; j < value.length; j++) {
                    const fName = `${fieldName}.${j}`
                    //const v = entity.getChildValue(parent, parentFieldInfo, `${field.name}.${j}`)
                    const fInfo = getFieldInfo(parent, `${field.name}.${j}`, parentFieldInfo)
                    if (fInfo.type === "ref") yield [fInfo, value[j], fName]
                    else if (fInfo.fields)
                        yield* refGenerator(entity, fName, value[j], fInfo, fInfo.fields)
                }
            }
        }
    }
}
const _updateRefs = (entity, g) => {
    const { value, done } = g.next()
    if (done) return entity

    const [field, val] = value
    if (!val) return _updateRefs(entity, g)

    const refType = entityTypes[field.ref]
    const coll = refType.collection

    const refItem = {
        coll,
        ref: val.ref,
        dep: entity._id.toString(),
        depColl: getCollection(entity),
    }
    const q = {
        collection: "refs",
        query: refItem,
    }
    //console.log(entity, field, val, refItem)
    return Query.select(q).then(data =>
        !data || !data.results || data.results.length === 0
            ? Query.insert({
                  collection: "refs",
                  data: refItem,
              }).then(() => _updateRefs(entity, g))
            : _updateRefs(entity, g)
    )
}
const updateRefs = entity => {
    return _updateRefs(entity, refGenerator(entity, null, entity, null, getFields(entity)))
}

const _updateDepEntity = (_idRef, entity, depEntity, g, updates, rest) => {
    const { value, done } = g.next()
    //console.log("dep", _idRef, entity, depEntity, updates, value, done)
    if (done) {
        if (updates === 0)
            return axios.delete(`data/refs/${_idRef.str}`).then(() => _updateDep(entity, rest))
        else return save(depEntity, null, true, true).then(() => _updateDep(entity, rest))
    }

    const [field, val, fieldName] = value
    const refType = entityTypes[field.ref]
    const collection = refType.collection
    if (!val || val.ref !== entity._id.toString() || collection !== getCollection(entity))
        return _updateDepEntity(_idRef, entity, depEntity, g, updates, rest)

    const v = { ...val }
    if (field.cache) {
        if (typeof field.cache === "string") {
            field.cache
                .split(",")
                .map(f => f.trim())
                .forEach(f => {
                    v[f] = f === "path" ? entity.path : get(entity, f)
                })
        } else field.cache(v, entity)
    }
    const de = set(depEntity, fieldName, v)

    return _updateDepEntity(_idRef, entity, de, g, updates + 1, rest)
}

const _updateDep = (entity, deps) => {
    if (deps.length === 0) return entity
    const [{ _id, dep, depColl }, ...rest] = deps

    return load(dep, depColl).then(depEntity =>
        _updateDepEntity(
            _id,
            entity,
            depEntity,
            refGenerator(depEntity, null, depEntity, null, getFields(depEntity)),
            0,
            rest
        )
    )
}
const updateDeps = entity => {
    const q = {
        collection: "refs",
        query: {
            ref: entity._id.toString(),
            coll: getCollection(entity),
        },
    }

    return Query.select(q).then(data => {
        //console.log("deps", q, data)
        if (!data || !data.results || data.results.length === 0) return entity
        return _updateDep(entity, data.results)
    })
}

const VerifyException = errors => ({ errors })
const _verifyEntity = (info, entity) => {
    let errors = {}
    if (!info) return true
    if (info.fieldCheck) {
        errors = Object.keys(info.fieldCheck)
            .map(field => ({
                field,
                errors: info.fieldCheck[field][0](entity[field], entity)
                    ? null
                    : { text: info.fieldCheck[field][1] },
            }))
            .filter(item => item.errors)
            .reduce((acc, el) => {
                acc[el.field] = el.errors
                return acc
            }, {})
    }
    info.fields
        .map(field => ({
            field: field.name,
            errors:
                fieldAdmin[field.type] && fieldAdmin[field.type].check
                    ? fieldAdmin[field.type].check[0](entity[field.name])
                        ? null
                        : { text: fieldAdmin[field.type].check[1] }
                    : null,
        }))
        .filter(item => item.errors)
        .forEach(item => {
            if (errors[item.field]) {
                if (errors[item.field].text)
                    errors[item.field].text = `${errors[item.field].text} ${item.errors.text}`
                else errors[item.field].text = item.errors.text
            } else {
                errors[item.field] = item.errors
            }
        })
    if (info.check) {
        const entityErrors = info.check(entity)
        if (entityErrors) {
            errors = { ...errors, ...entityErrors }
        }
    }
    /*
	if (Object.keys(errors).length > 0) {
			dispatch({ type: "errors", errors })
			return false
	}
	return true
	*/
    if (Object.keys(errors).length > 0) throw VerifyException(errors)
    return true
}
const save = async (entityToSave, remove, norefs = false, noredirect = false) => {
    let entity = entityToSave
    const info = getInfo(entity)
    _verifyEntity(info, entity)
    if (info && info.presave) entity = info.presave(entity)
    const oldPath = is(entity, "node") ? entity.path : null
    //entity.presave()
    if (is(entity, "node")) {
        //if (!entity._c) entity._c = { pathauto: true }
        //entity._c = { ...entity._c }
        //if (entity._c.pathauto === undefined) entity._c.pathauto = true
        let pathauto = true
        if (entity._c && entity._c.pathauto === false) pathauto = false
        if (pathauto && info.pathinfo) {
            if (typeof info.pathinfo === "string") entity._c.pathinfo = info.pathinfo
            else entity._c.pathinfo = info.pathinfo(entity)
        }
    }
    //console.log(entity._id.toString())
    if (isNew(entity)) {
        //if (LANGUAGES) entity._lang = state.defaultLanguage
        const { _id, ...data } = entity
        const response = await Query.insert({
            collection: info.collection,
            data,
        })

        const res = await Query.select({
            collection: info.collection,
            query: { _id: response.data.insertedId },
        })

        if (!res || !res.total === 1) {
            console.log("Just saved entity not found.")
            return null
        }
        let e = res.results[0]
        if (info.afterSave) info.afterSave(e)
        e = await updateRefs(e)
        e = updateDeps(e)
        if (is(e, "node") && !noredirect) {
            if (!C.LANGUAGES) History.push(e.path)
            else if (e.path.length > 0) History.push(e.path[0].p)
        }
        return e
    } else {
        const { _id, $unset, ...$set } = entity
        /*console.log({
                collection: info.collection,
                data: { $set, $unset },
                remove,
                _id,
            })*/
        const response = await Query.update({
            collection: info ? info.collection : "node",
            data: { $set, $unset },
            remove,
            _id,
        })
        let e = entity
        if (info && info.afterSave) info.afterSave(e)
        if (response.data.redirect) e.path = response.data.redirect
        if (!norefs) {
            e = await updateRefs(e)
            e = await updateDeps(entity)
        }
        if (
            typeof window !== "undefined" &&
            C.LANGUAGES === null &&
            is(e, "node") &&
            oldPath === document.location.pathname &&
            oldPath !== e.path &&
            !noredirect
        ) {
            History.push(e.path)
        }
        return e
    }
}

const create = (type, language) => {
    const typeInfo = getTypeInfo(type)
    if (!typeInfo) return null

    let entity = { _id: {}, type: typeInfo.type }
    if (C.LANGUAGES) {
        entity._lang = C.LANGUAGES[0]
    }
    const info = getInfo(entity)
    //entity = wrapInfo(entity)

    info.fields.forEach(field => {
        if (!field.default) return

        entity = set(entity, field.name, field.default, language)
    })
    if (info.onNew) entity = info.onNew(entity)

    return entity
}

const load = (_id, collection = "node") => {
    const options = {
        collection,
        query: { _id },
    }

    return Query.select(options).then(results => results.results[0])
    /*return axios
        .post("datamulti", { params: { options } })
        .then(response => {
            if (
                response.data &&
                response.data.length === 1 &&
                response.data[0].results &&
                response.data[0].results.length === 1
            )
                return response.data[0].results[0]
            return null
        })
        .catch(error => {
            console.log(error)
            return null
        })*/
}
const remove = entity => {
    const id = getId(entity)
    const info = getInfo(entity)
    if (!id || !info) return
    const collection = info.collection || "node"
    return Query.remove(id, collection)
}

export { save, create, load, remove }
