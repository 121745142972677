import React from "react"
import Entity from "../../../entity"

const FormFieldText = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const [focus, setFocus] = React.useState()
    const handleChange = e => {
        //console.log(info.fieldInfo, value)
        dispatch({
            type: "change",
            field: info.field,
            label: value.label,
            fieldType: value.fieldType,
            value: e.currentTarget.value,
        })
    }
    const handleFocus = React.useCallback(() => {
        setFocus(true)
    }, [])
    const handleBlur = React.useCallback(() => {
        setFocus(false)
    }, [])
    const label = Entity.get(value, "label", info.language)
    const args = focus || fieldValue ? { "label-up": "" } : {}
    return (
        <div ref={domRef} {...props} {...args}>
            <label htmlFor={info.field}>{label}</label>
            <input
                name={info.field}
                type="text"
                value={fieldValue || ""}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    )
}
export default FormFieldText
