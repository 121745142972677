import React from "react"
import { AuthButton, Link, FaIcon, realPath } from "lib"
import { useMainMenu } from "../header/MainMenu"

const Footer = ({ node, language }) => {
    const menu = useMainMenu()
    if (!node) return null

    return (
        <footer id="footer">
            <div className="footer1">
                <a
                    href="https://sgg.gov.ro/new/guvernare-transparenta-deschisa-si-participativa-standardizare-armonizare-dialog-imbunatatit-cod-sipoca-35/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src="https://telciu.ro/img/sipoca.jpg" alt="SIPOCA" />
                </a>
            </div>
            <div className="footer2">
                <Link to="/">
                    <div className="footer-logo">
                        <img src={realPath("/img/logo.png")} alt="Logo" />
                    </div>
                    <div className="site-name">Primăria Comunei Telciu</div>
                </Link>
                {menu && (
                    <ul className="footer-menu ">
                        {menu.map((submenuItem, j) => (
                            <li key={j}>
                                <Link to={submenuItem.p}>{submenuItem.title}</Link>
                            </li>
                        ))}
                    </ul>
                )}

                <div className="contact">
                    <div>
                        <FaIcon icon="faMapMarkerAlt" size={32} /> Strada Principală Nr. 744B,
                        Telciu,
                        <br />
                        Jud Bistrița-Năsăud, România
                    </div>
                    <div>
                        <FaIcon icon="faPhone" size={32} />
                        <a href="tel:+40263369004">+40 263 369 004</a>
                    </div>
                    <div>
                        <FaIcon icon="faEnvelope" size={32} />
                        <a href="mailto:primariatelciubn@yahoo.com">primariatelciubn@yahoo.com</a>
                    </div>
                </div>
            </div>

            <div className="copyright">
                Copyright © 2000-{new Date().getFullYear()} Primăria comunei Telciu
            </div>

            <AuthButton />
        </footer>
    )
}
export default React.memo(Footer)
